import * as actionTypes from './types';
import { endpoints } from '../../../api/endpoints';
import * as api from '../../../api/base';
import store from '../..';
import queryString from 'query-string';

export const setAdminFarmId = (farmId) => {
    return {
        type: actionTypes.SET_ADMIN_FARM_ID,
        payload: farmId
    };
};

export const getAllFarms = (params) => async (dispatch) => {
    const response = await api.get(endpoints.admin.farm.farms.concat('?', queryString.stringify(params)));
    if (response && response.status === 200) {
        return dispatch(getAllFarmsSuccess(response.data));
    }
    else {
        dispatch(getAllFarmsFail());
        return dispatch(getAllFarmsFailState(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
    }
};

const getAllFarmsSuccess = (response) => {
    return {
        type: actionTypes.GET_ALL_FARMS_SUCCESS,
        payload: response
    };
};

const getAllFarmsFail = () => {
    return {
        type: actionTypes.GET_ALL_FARMS_FAIL,
    };
};

const getAllFarmsFailState = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_FAIL,
        payload: response

    };
};

export const getAdminFarm = (farmId) => async (dispatch) => {
    try {
        const response = await api.get(endpoints.admin.farm.farm.concat('?farmId=', farmId));
        if (response && response.status === 200) {
            const { farmOwnerUserId } = response.data;
            dispatch((getAdminFarmOwnerData(farmOwnerUserId)))
            return dispatch(getAdminFarmSuccess(response.data));
        }
        else {
            return dispatch(getAdminFarmFail(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
        }
    } catch (e) {
        return dispatch(getAdminFarmFail(e?.message));
    }
};

const getAdminFarmSuccess = (response) => {
    return {
        type: actionTypes.GET_ADMIN_FARM_DATA_SUCCESS,
        payload: response
    };
};

const getAdminFarmFail = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_FAIL,
        payload: response
    };
};

export const getAdminFarmOwnerData = (accountId) => async (dispatch) => {
    const response = await api.get(endpoints.admin.user.account.concat(accountId));
    if (response && response.status === 200) {
        return dispatch(getAdminFarmOwnerDataSuccess(response.data));
    }
    else {
        return dispatch(getAdminFarmOwnerDataFail(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
    }
};

const getAdminFarmOwnerDataSuccess = (response) => {
    return {
        type: actionTypes.GET_ADMIN_FARM_OWNER_DATA_SUCCESS,
        payload: response
    };
};

const getAdminFarmOwnerDataFail = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_FAIL,
        payload: response
    };
};

export const createAdminFarm = (data) => async (dispatch) => {
    try {
        const response = await api.post(endpoints.admin.farm.farm,
            {
                farmName: data?.farmName,
                holdingNumber: data?.holdingNumber,
                businessReferenceNumber: data?.businessReferenceNumber,
                addressLine1: data?.addressLine1,
                addressLine2: data?.addressLine2,
                townCity: data?.townCity,
                countryId: data?.countryId,
                zipPostCode: data?.zipPostCode,
                isFarmAdvisoryService: data?.isFarmAdvisoryService,
                farmOwnerUserId: data?.farmOwnerUserId,
                organizationId: data?.organisationId || null,
                officeId: data?.officeId || null,
                license: data?.license,
                externalFarmId:data?.externalFarmId
            }
        );
        if (response && response.status === 200) {
            return dispatch(createAdminFarmSuccess("Farm has been created successfully."));
        }
        else {
            return dispatch(createAdminFarmFail(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
        }
    } catch (e) {
        return dispatch(createAdminFarmFail(e?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
    }
};

const createAdminFarmSuccess = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_SUCCESS,
        payload: response
    };
};

const createAdminFarmFail = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_FAIL,
        payload: response
    };
};


export const updateAdminFarm = (data) => async (dispatch) => {
    try {
        const response = await api.put(endpoints.admin.farm.farm,
            {
                id: data?.id,
                farmName: data?.farmName,
                holdingNumber: data?.holdingNumber,
                businessReferenceNumber: data?.businessReferenceNumber,
                addressLine1: data?.addressLine1,
                addressLine2: data?.addressLine2,
                townCity: data?.townCity,
                countryId: data?.countryId,
                zipPostCode: data?.zipPostCode,
                isFarmAdvisoryService: data?.isFarmAdvisoryService,
                farmOwnerUserId: data?.farmOwnerUserId,
                organizationId: data?.organisationId || null,
                officeId: data?.officeId || null,
                license: data?.license,
                externalFarmId:data?.externalFarmId
            }
        );

        if (response && response.status === 200) {
            return dispatch(updateAdminFarmSuccess("Farm has been successfully updated"));
        }
        else {
            return dispatch(updateAdminFarmFail(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
        }
    } catch (e) {
        return dispatch(updateAdminFarmFail(e?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
    }
}

const updateAdminFarmSuccess = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_SUCCESS,
        payload: response
    };
};

const updateAdminFarmFail = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_FAIL,
        payload: response
    };
};

export const deleteAdminFarm = () => async (dispatch) => {
    const { farmId } = store.getState().adminFarm;
    const response = await api.del(endpoints.admin.farm.farm.concat('?farmId=', farmId));
    if (response && (response.status === 200 || response.status === 204)) {
        return dispatch(deleteAdminFarmSuccess(response.data || "The Farm has been successfully deleted"));
    }
    else {
        return dispatch(deleteAdminFarmFail(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
    }
};

const deleteAdminFarmSuccess = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_SUCCESS,
        payload: response
    };
};

const deleteAdminFarmFail = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_FAIL,
        payload: response

    };
};



