import { List, Paper } from '@mui/material'
import React, { } from 'react'
import { FormHeaderPaper, ParagraphTypographyPDF } from '../../../../components/CustomStyles/StyledComponents';

const Contents = ({ results = {}, firstChild = false }) => {
    return (
        <Paper sx={{ maxWidth: '860px', pageBreakAfter: 'always',
            pageBreakInside: 'avoid', marginTop: firstChild ? '5mm' : '2mm',
            pt:1}} >
            <FormHeaderPaper sx={{ p: 2, display: 'flex', flexDirection: 'column', }} divider={false}>
                <ParagraphTypographyPDF variant="body2" paragraph>
                    *Your carbon footprint is expressed in units of CO<sub>2</sub> equivalents
                    (CO<sub>2</sub>e) per unit of output e.g. kg CO<sub>2</sub>e per kg dwt 
                    of cold carcase. This allows the efficiency of the enterprise to be compared.
                    The main greenhouse gases emitted by agriculture are:
                    <ul>
                    <li>CH<sub>4</sub> = Methane (Predominantly from animal digestion);</li>
                    <li>N<sub>2</sub>O = Nitrous oxide (Predominantly from manure and fertiliser);</li>
                    <li>CO<sub>2</sub> = Carbon dioxide (Predominantly from burning of fossil fuels).</li>
                    </ul>
                </ParagraphTypographyPDF>
                <ParagraphTypographyPDF variant="body2" paragraph>
                    {`** Other includes crop residues, lime, transport and waste.`}
                </ParagraphTypographyPDF>
                <ParagraphTypographyPDF variant="body2" paragraph>
                    {`*** Total emission may differ due to rounding.  
                    Emissions may be skewed on a year to year basis due to timing of sales therefore results are best monitored over a three year (minimum) period.`}
                </ParagraphTypographyPDF>
            </FormHeaderPaper>
        </Paper>
    )
}

export default Contents;