import { Button, FormControl, Grid, List,
         ListSubheader, MenuItem, Paper,
         Skeleton, Switch} from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toastr from 'toastr';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import _ from 'lodash';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { isEqual } from "lodash";
import {
  FormHeaderPaper, FormTitleTypography, FormRowGroupTableRow,
  FormTableCell, SectorTitleTypography, TableListItem, DataEntryTextField, DataEntrySelect, FormDescriptionTypography
} from '../../../components/CustomStyles/StyledComponents';
import { toastrCustomOptions, toastrCustomOptionsLonger } from '../../../constants/toastrOptions';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import produce from 'immer';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import {
  getFertiliserOptions,
  getFertiliserFormData,
  getFertiliserMetaData,
  resetResponseState,
  savFertiliserData,
  setFertiliserFormData,
  setLoading,
  unsetLoading,
  resetFertiliserMetaData
} from '../../../store/appAction';
import { addCommas } from '../../../helpers/stringFormatHelper';
import { getFirstVisibleCellDetailsInDataEntry } from '../../../helpers/dataEntryHelper';
import { createNewRecord, deleteRow } from '../../../helpers/flexibleDataRowsHelper.js';
import { isObjectEmpty } from '../../../helpers/genericHelper.js';
import DataEntryButtonStack from '../../../components/Buttons/DataEntryButtonStack';
import SelectedFarm from '../../../components/SelectedFarm/SelectedFarm';
import SelectedReport from '../../../components/SelectedReport/SelectedReport';
import store from '../../../store';

const CELL_WIDTH = {
  RowTitle: "15%",
  FertiliserLime: "26%",
  FertiliserId: "26%",
  TotalN: "9%",
  TotalP: "9%",
  TotalK: "9%",
  Quantity: "9%",
  ContainsNitrificationOrInhibitor: "9%",
  FertiliserInhibitor: "9%",
  Add: "7%",
  Delete: "7%",
};

const Total_N_KEY = 'TotalN';
const Total_P_KEY = 'TotalP';
const Total_k_KEY = 'TotalK';
const FERTILISER_ID_KEY = 'FertiliserId';
const QUANTITY_KEY = 'Quantity';

const DataEntryTable = ({ sector, initialFormData, fertiliserOptions, isMounted, firstVisibleCellDetails, locked}) => {
  toastr.options = toastrCustomOptions;

  const dispatch = useDispatch();
  const [sectorDataInt, setSectorDataInt] = useState(undefined);
  const [sectorDataExt, setSectorDataExt] = useState(undefined);

  useEffect(() => {
    setSectorDataInt(initialFormData);
    setSectorDataExt(initialFormData);
  }, [initialFormData])

  useEffect(() => {
    if (sectorDataExt && !_.isEmpty(sectorDataExt)) {
      dispatch(setFertiliserFormData({
        sectorId: sector.id,
        formData: sectorDataExt
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectorDataExt]);

  const handleInputChange = (e, groupId, rowId, subRowIdx, mappingProperty, cell, totalArea) => {
    let { value, checked } = e.target;

    if (cell.dataType === 'TextField') {
      let numericRegex = /^[0-9]*(\.[0-9]{0,2})?$/;
      if (numericRegex.test(value)) {
        let numericValue = parseFloat(value || 0);
        const rule = cell.validations;
        const max = rule?.max || parseFloat(2 * (totalArea || 0));
        if (max === 0) {
          toastr.warning(`Please enter Land Area information and try again.`,
            undefined,
            toastrCustomOptionsLonger
          );
        } else if (rule.min > numericValue || rule.max < numericValue) {
          toastr.warning(`This input only accepts values between ${addCommas(rule.min)} and ${addCommas(max)}.  Please enter the value again.`,
            undefined,
            toastrCustomOptionsLonger
          );
        } else {
          setSectorDataInt(produce((draft) => {
            draft[groupId][rowId][subRowIdx].value[mappingProperty] = value || null;
          }));
          setSectorDataExt(produce((draft) => {
            draft[groupId][rowId][subRowIdx].value[mappingProperty] = numericValue || null;
          }));
        }
      }
    }
    else if (cell.dataType === 'Select') {
      const selectObj = _.find(fertiliserOptions, { id: value });

      setSectorDataInt(produce((draft) => {
        draft[groupId][rowId][subRowIdx].value[mappingProperty] = value || null;

        draft[groupId][rowId][subRowIdx].value[Total_N_KEY] = selectObj?.nFrac || null;
        draft[groupId][rowId][subRowIdx].value[Total_P_KEY] = selectObj?.p2O5Frac || null;
        draft[groupId][rowId][subRowIdx].value[Total_k_KEY] = selectObj?.k2OFrac || null;
      }));
      setSectorDataExt(produce((draft) => {
        draft[groupId][rowId][subRowIdx].value[mappingProperty] = value || null;
        draft[groupId][rowId][subRowIdx].value[Total_N_KEY] = selectObj?.nFrac || null;
        draft[groupId][rowId][subRowIdx].value[Total_P_KEY] = selectObj?.p2O5Frac || null;
        draft[groupId][rowId][subRowIdx].value[Total_k_KEY] = selectObj?.k2OFrac || null;
      }));
    } else if (cell.dataType === 'Switch') {
      setSectorDataInt(produce((draft) => {
        draft[groupId][rowId][subRowIdx].value[mappingProperty] = checked;
      }));
      setSectorDataExt(produce((draft) => {
        draft[groupId][rowId][subRowIdx].value[mappingProperty] = checked;
      }));
    }
  };

  const getCellContent = (groupId, rowId, subRowIdx, cell, cellValue, totalArea, firstVisibleCellDetails, locked) => {
    switch (cell.dataType) {
      case "Select":
        return (
          <FormTableCell sx={{
            width: CELL_WIDTH[cell.variant]
          }}>
            <FormControl sx={{ width: '98%', margin: 0 }}>
              <DataEntrySelect
                value={cellValue !== null ? cellValue : ''}
                displayEmpty
                onChange={(e) => handleInputChange(e, groupId, rowId, subRowIdx,
                  cell.mappingProperty, cell, totalArea)}
                autoFocus={locked === false && rowId === firstVisibleCellDetails.rowId && cell.id === firstVisibleCellDetails.cellId && subRowIdx === 0}
              >
                <MenuItem value=""><em>Please Select</em></MenuItem>
                {fertiliserOptions.map((option) => (
                  <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                ))}
              </DataEntrySelect>
            </FormControl>
          </FormTableCell>
        );
      case "TextField":
        return (
          <FormTableCell sx={{ width: CELL_WIDTH[cell.mappingProperty] }}>
            <DataEntryTextField
              size="small"
              variant="outlined"
              inputProps={{ inputMode: "numeric" }}
              style={{ width: 75, textAlign: "center" }}
              value={cellValue || ''}
              onChange={(e) => handleInputChange(e, groupId, rowId, subRowIdx,
                cell.mappingProperty, cell, totalArea)}
              autoFocus={locked === false && rowId === firstVisibleCellDetails.rowId && cell.id === firstVisibleCellDetails.cellId && subRowIdx === 0}
            />
          </FormTableCell>
        );
      case 'Switch':
        return (
          <FormTableCell sx={{ width: CELL_WIDTH[cell.mappingProperty] }}>
            <Switch
              size="small"
              checked={cellValue || false}
              onChange={(e) => handleInputChange(e, groupId, rowId, subRowIdx,
                cell.mappingProperty, cell, totalArea)} 
                autoFocus={locked === false && rowId === firstVisibleCellDetails.rowId && cell.id === firstVisibleCellDetails.cellId && subRowIdx === 0}
            />
          </FormTableCell>
        );
      default:
        break;
    }
  }

  const fetchRowTitle = (title, totalArea) => {
    const totalInKm = ['Hedges']; //temporarily check by title
    return title.concat(
      ' (', addCommas(totalArea || 0),
      ' ', totalInKm.includes(sector.title) ? 'km' : 'ha', ')');
  }


  const addNewRow = (groupId, subRowValues, rowId) => {
    let newObject = createNewRecord(subRowValues);
    setSectorDataInt(produce((draft) => {draft[groupId][rowId].push(newObject)} ));
    setSectorDataExt(produce((draft) => {draft[groupId][rowId].push(newObject)} ));
  }

  const deleteNewRow = (groupId, subRowIdx, rowId) => {
    let newId = uuidv4();
    setSectorDataInt(produce((draft) => {deleteRow(draft[groupId][rowId], subRowIdx, newId)}));
    setSectorDataExt(produce((draft) => {deleteRow(draft[groupId][rowId], subRowIdx, newId)}));
  }

  return (
    <>
      {sector ?
        <ScrollSyncPane>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
              <TableBody>
                {(sector?.sectorGroups?.groups || []).map((group) => (
                  <Fragment>
                    {(group?.rows || []).map((row) => {
                      const rowData = sectorDataInt &&
                        sectorDataInt[group.id] ?
                        sectorDataInt[group.id][row.id] : [];
                      const activeRowData = rowData.filter((subRow) => !subRow.deleted);
                      return (
                        <Fragment>
                          <FormRowGroupTableRow>
                            <FormTableCell sx={{ width: CELL_WIDTH['RowTitle'] }} rowSpan={activeRowData.length + 1} >
                              {fetchRowTitle(row.title, row.totalArea)}
                            </FormTableCell>
                          </FormRowGroupTableRow>
                          {rowData.map((subRow, subRowIdx) => {
                            let subRowValues = subRow.value;
                            let lastElementOfActiveRowData = activeRowData[activeRowData.length - 1];
                            return (
                              !subRow.deleted &&
                              <FormRowGroupTableRow>
                                {(row?.cells || []).map((cell) => (
                                  getCellContent(group.id, row.id, subRowIdx, cell,
                                    subRowValues[cell.mappingProperty], row.totalArea,firstVisibleCellDetails, locked)
                                ))}
                                <FormTableCell sx={{ width: CELL_WIDTH['Add'] }}>
                                {isMounted && lastElementOfActiveRowData.recordId === subRow.recordId &&
                                    !([subRowValues[FERTILISER_ID_KEY], subRowValues[QUANTITY_KEY]]
                                      .includes(null)) &&
                                    <Button
                                      style={{ padding: '3px 8px' }}
                                      onClick={() => addNewRow(group.id, subRowValues, row.id)}>
                                      <AddIcon />
                                    </Button>
                                  }
                                </FormTableCell>
                                <FormTableCell sx={{ width: CELL_WIDTH['Delete'] }}>
                                {(isMounted && (!isObjectEmpty(subRowValues) || activeRowData.length > 1)) &&
                                    <Button
                                      style={{ padding: '3px 8px' }}
                                      onClick={() => deleteNewRow(group.id, subRowIdx, row.id)}>
                                      <DeleteIcon />
                                    </Button>
                                  }
                                </FormTableCell>
                              </FormRowGroupTableRow>
                            );
                          })}
                        </Fragment>
                      )
                    })}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </ScrollSyncPane>
        : <Skeleton variant="rectangular" width={'100%'} height={150} />
      }
    </>
  );
};

const Fertiliser = (props) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const reportId = useSelector(state => state.common.reportId);
  const sectors = useSelector(state => { return state?.fertiliser?.metaData?.form?.sectors || []} );
  const columns = useSelector(state => { return state?.fertiliser?.metaData?.form?.columns || []} );
  
  const fertiliserOptions = useSelector(state => state.fertiliser.fertiliserOptions);
  const initialFormData = useSelector(state => state.fertiliser.initialFormData);
  const { successMsg, errorMsg } = useSelector(state => state.common);
  const farmId = useSelector(state => state.common.farmId);
  const adminFarm = useSelector(state => state.adminFarm);
  const {reportDetails: { reportName }} = useSelector(state => state.farmReport);
  const [isMounted, setIsMounted] = useState(false);

  const [formData, setFormData] = useState({})
  const [modalOpen, setModalOpen] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const [firstVisibleCellDetails, setFirstVisibleCellDetails] = useState(null);


  useEffect(() => {
   window.scrollY = 0;
    dispatch(setLoading());
    dispatch(getFertiliserOptions());
    dispatch(getFertiliserMetaData({ reportId }));
    dispatch(getFertiliserFormData({ reportId }));
    dispatch(resetResponseState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      dispatch(resetFertiliserMetaData());
    }
  }, [dispatch,reportId]);

  useEffect(() => {
    setTimeout(function() { setIsMounted(true); dispatch(unsetLoading());}, 3000);
    return () => setIsMounted(false)
  }, [dispatch]);

  useEffect(() => {
    if (initialFormData && !_.isEmpty(initialFormData)) {
      const tempData = { ...initialFormData };
      setFormData(tempData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFormData]);

  useEffect(() => {
    if (successMsg || errorMsg) {
      dispatch(getFertiliserFormData({ reportId }));
      setTimeout(() => {
        dispatch(unsetLoading());
    }, 2000);
      successMsg && toastr.success(successMsg);
      errorMsg && toastr.error(errorMsg, undefined, toastrCustomOptionsLonger);
      dispatch(getFertiliserFormData({ reportId }));
      dispatch(resetResponseState());
      if (successMsg && nextClicked) {
        navigate('/data-entry/land-crops/organic-material-manures');
      }
      setNextClicked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successMsg, errorMsg]);

  useEffect(() => {
    const firstVisibleCellDetails = getFirstVisibleCellDetailsInDataEntry(sectors);
    setFirstVisibleCellDetails(firstVisibleCellDetails);
  }, [sectors]);

  const handleModalResponse = (positiveResponse) => {
    setModalOpen(false);
    if (positiveResponse) {
      // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
     window.scrollY = 0;
      dispatch(getFertiliserMetaData({ reportId }));
      dispatch(getFertiliserFormData({ reportId }));
    }
  }

  const isFormUpdated = () => {
    const fertiliserData = store.getState().fertiliser;
    const isUpdated = !isEqual(fertiliserData?.initialFormData, fertiliserData?.currentFormData);
    return isUpdated;
  }

  const handleSaveClick = (e) => {
    if(!isFormUpdated()){
      return;
    }
    dispatch(setLoading());
    dispatch(savFertiliserData({ reportId }));
  }

  const handleNextClick = (e) => {
    setNextClicked(true);
    if (props.locked || !isFormUpdated()){
      navigate('/data-entry/land-crops/organic-material-manures');
    } 
    else {
      dispatch(setLoading());
      dispatch(savFertiliserData({ reportId }));
    }
  }

  return (
    <ScrollSync>
      <Grid container spacing={2} className={props.locked && 'deactivated'}>
        <Grid item xs={12} md={12} lg={12}>
          <FormHeaderPaper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
            divider={false}
          >
            <SelectedFarm farmId={farmId} farmName={adminFarm.farmDetails.farmName}/>
            <SelectedReport reportName={reportName} />
            <FormTitleTypography variant="h5" component="div">
              Fertiliser & Lime
            </FormTitleTypography>
            <FormDescriptionTypography variant="body2" paragraph>
              Enter information on all inorganic fertilisers and lime applied to farm soils.
            </FormDescriptionTypography>
          </FormHeaderPaper>
        </Grid>
        <Grid item xs={12} sx={{ paddingTop:'0 !important' }}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', paddingTop:'0' }}>
            <List sx={{ mb: 2 }}>
              <ListSubheader sx={{ bgcolor: "background.paper" }}>
                <ScrollSyncPane>
                  <>
                  <DataEntryButtonStack
                    modalOpen={modalOpen}
                    setModalOpen={setModalOpen}
                    handleModalResponse={handleModalResponse}
                    handleSaveClick={handleSaveClick}
                    handleNextClick={handleNextClick}
                  />
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <FormTableCell sx={{ width: CELL_WIDTH['RowTitle'] }}></FormTableCell>
                          {columns.map((column) =>
                            <FormTableCell align="center" sx={{ width: CELL_WIDTH[column.cssClass] }} >
                              {column.title}
                            </FormTableCell>
                          )}
                          <FormTableCell
                            align="center"
                            sx={{ width: CELL_WIDTH['Add'] }}
                          >
                            Add
                          </FormTableCell>
                          <FormTableCell
                            align="center"
                            sx={{ width: CELL_WIDTH['Delete'] }}
                          >
                            Delete
                          </FormTableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </TableContainer>
                  </>  
                </ScrollSyncPane>
              </ListSubheader>
              {sectors.map((sector, index) => (
                <React.Fragment key={index}>
                  { sector.sectorGroups?.groups?.some(group => group.rows?.length > 0) &&
                    <>
                      <TableListItem>
                        <SectorTitleTypography variant='body1'>{sector.title}</SectorTitleTypography>
                      </TableListItem>
                      <TableListItem>
                      <DataEntryTable
                        key={sector.id}
                        sector={sector}
                        sectorId={sector.id}
                        fertiliserOptions={fertiliserOptions}
                        initialFormData={formData[sector.id] ? formData[sector.id] : {}}
                        isMounted={isMounted}
                        firstVisibleCellDetails={firstVisibleCellDetails}
                        locked= {props.locked}
                      />
                    </TableListItem>
                    </>
                  }

                </React.Fragment>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </ScrollSync>
  );
}

export default Fertiliser;