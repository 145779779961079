import { Card, CardActionArea, CardContent, Grid, Paper, } from '@mui/material'
import React, { } from 'react'
import { FormDescriptionTypographyPDF, FormTitleTypographyPDF } from '../../../../components/CustomStyles/StyledComponents';
import CircularPieChart from '../../../../components/Charts/CircularPieChart';
import ColoredPieChart from '../../../../components/Charts/ColoredPieChart';
import { addCommas } from '../../../../helpers/stringFormatHelper';
import VerticalBarsChart from '../../../../components/Charts/VerticalBarsChart';
import { table2ChartData } from '../../../../helpers/reportResultsHelper';

const Charts = ({ chartsResults = {}, results = {}, firstChild = false}) => {
    return (
        <Paper sx={{ maxWidth: '860px',pageBreakAfter: 'auto', marginTop: '5mm'}} p={2}>
            <Grid item xs={12} md={12} lg={12} mb={1}>
                <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column'}}>
                    <FormTitleTypographyPDF component="div" mb={1}>
                        {results.enterpriseTitle || '-Enterprise Name-'}
                    </FormTitleTypographyPDF>
                    <Grid container direction="row" spacing={0}>
                        <Grid item xs={6}>
                            <FormDescriptionTypographyPDF paragraph fontWeight="bold" mb={0} pr={1}>
                                {'Sector: '}
                                <FormDescriptionTypographyPDF paragraph display="inline" mb={0}>
                                    {results.sector}
                                </FormDescriptionTypographyPDF>
                            </FormDescriptionTypographyPDF>
                        </Grid>
                        <Grid item xs={6}>
                            <FormDescriptionTypographyPDF paragraph fontWeight="bold" mb={0} pr={1}>
                                {'Organic: '}
                                <FormDescriptionTypographyPDF paragraph display="inline" mb={0}>
                                    {results.isOrganic ? 'Yes' : 'No'}
                                </FormDescriptionTypographyPDF>
                            </FormDescriptionTypographyPDF>
                        </Grid>
                        <Grid item xs={6}>
                            <FormDescriptionTypographyPDF paragraph fontWeight="bold" mb={0} pr={1}>
                                {'Enterprise Type: '}
                                <FormDescriptionTypographyPDF paragraph display="inline" mb={0}>
                                    {results.enterpriseType}
                                </FormDescriptionTypographyPDF>
                            </FormDescriptionTypographyPDF>
                        </Grid>
                        <Grid item xs={6}>
                            <FormDescriptionTypographyPDF paragraph fontWeight="bold" mb={0} pr={1}>
                                {'Compare To: '}
                                <FormDescriptionTypographyPDF paragraph display="inline" mb={0}>
                                    {results.comparedTo || '-Compare To-'}
                                </FormDescriptionTypographyPDF>
                            </FormDescriptionTypographyPDF>
                        </Grid>
                        <Grid item xs={6}>
                            <FormDescriptionTypographyPDF paragraph fontWeight="bold" mb={0} pr={1}>
                                {'System: '}
                                <FormDescriptionTypographyPDF paragraph display="inline" mb={0}>
                                    {results.system}
                                </FormDescriptionTypographyPDF>
                            </FormDescriptionTypographyPDF>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12} md={12} lg={12} mb={1}>
                <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column'}} >
                    <FormDescriptionTypographyPDF variant="body2" paragraph mb={0}>
                        {`This enterprise-level report further breaks down emissions from a specific farm enterprise. Again, carbon
                            dioxide equivalent emissions are reported by source and by gas. Product level emissions by source and 
                            are shown against those of similar enterprises.`}
                    </FormDescriptionTypographyPDF>
                </Paper>
            </Grid>
            <Grid item xs={12} mb={1} px={0}>
                <Grid container direction='row'>
                    <Grid item xs={3} p={1} >
                        <Card sx={{ textAlign: 'center', height: '100%' }} variant='outlined'>
                            <CardActionArea sx={{ height: '100%' }}>
                                <CardContent sx={{ padding: '5px' }}>
                                    <FormDescriptionTypographyPDF gutterBottom variant="h6" fontSize={13} mb={0} component="div">
                                        Total Emissions:
                                    </FormDescriptionTypographyPDF>
                                    {chartsResults && <FormDescriptionTypographyPDF variant="h6" fontSize={14} fontWeight="bold" component="div" color="#cc5500">
                                        {addCommas(chartsResults.totalEmissions)}
                                    </FormDescriptionTypographyPDF>}
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={3} p={1}>
                        <Card sx={{ textAlign: 'center', height: '100%' }} variant='outlined'>
                            <CardActionArea sx={{ height: '100%' }}>
                                <CardContent sx={{ padding: '5px' }}>
                                    <FormDescriptionTypographyPDF gutterBottom variant="h6" fontSize={13} mb={0} component="div">
                                        Product Emissions:
                                    </FormDescriptionTypographyPDF>
                                    {chartsResults && <FormDescriptionTypographyPDF variant="h6" fontSize={14} fontWeight="bold" component="div" color="#cc5500">
                                        {addCommas(chartsResults.productEmissions)}
                                    </FormDescriptionTypographyPDF>}
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={3} p={1} >
                        <Card sx={{ textAlign: 'center', height: '100%' }} variant='outlined'>
                            <CardActionArea sx={{ height: '100%' }}>
                                <CardContent sx={{ padding: '5px' }}>
                                    <FormDescriptionTypographyPDF gutterBottom variant="h6" fontSize={13} mb={0} component="div">
                                        Largest Source:
                                    </FormDescriptionTypographyPDF>
                                    {chartsResults && <FormDescriptionTypographyPDF variant="h6" fontSize={14} fontWeight="bold" component="div" color="#cc5500">
                                        {chartsResults.largestSource}
                                    </FormDescriptionTypographyPDF>}
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={3} p={1}>
                        <Card sx={{ textAlign: 'center', height: '100%' }} variant='outlined'>
                            <CardActionArea sx={{ height: '100%' }}>
                                <CardContent sx={{ padding: '5px' }}>
                                    <FormDescriptionTypographyPDF gutterBottom variant="h6" fontSize={13} mb={0} component="div">
                                        Highest Gas Emission:
                                    </FormDescriptionTypographyPDF>
                                    {chartsResults && <FormDescriptionTypographyPDF variant="h6" fontSize={14} fontWeight="bold" component="div" color="#cc5500">
                                        {chartsResults.highestGasEmission}
                                    </FormDescriptionTypographyPDF>}
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} px={0}>
                <Grid container direction='row' spacing={0}>
                    <Grid item xs={6} pl={0}>
                        <Paper sx={{ pl: 0 }}>
                            <ColoredPieChart isAnimationActive={false}
                                key={1}
                                title='Emissions by Source'
                                dataset={table2ChartData(results)?.emissionSource}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={6} pl={2}>
                        <Paper>
                            <CircularPieChart
                                isAnimationActive={false}
                                key={2}
                                title='Emissions by Gas'
                                dataset={chartsResults.emissionByGas}
                            />
                        </Paper>
                    </Grid>
                </Grid>
                <Grid container direction='row' spacing={0}>
                    <Grid item xs={12} pl={0}>
                        <Paper sx={{ width: '95%', margin: '0 auto', mt: 3 }}>
                            <VerticalBarsChart
                                isAnimationActive={false}
                                key={3}
                                title='Emissions vs Comparison'
                                dataset={chartsResults.emissionVsComparison}
                                emissionUnit={chartsResults?.emissionUnit}
                                aspectRatio = {2.75}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default Charts