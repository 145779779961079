import * as actionTypes from './types';
import { endpoints } from '../../api/endpoints';
import * as api from '../../api/base';
import { setSession, deleteSession } from '../../helpers/authHelper';
import jwt_decode from "jwt-decode";
import { APP_SCOPE, APP_CLIENT_ID } from '../../config';
import { USER_INACTIVITY_MESSAGE, BACKEND_USER_INACTIVITY_MESSAGE } from '../../constants/messages'
import { SUPER_ADMIN } from '../../constants/userRoles'
import store from '../../store';

const GRANT_TYPE = 'password';

export const signIn = ({ username, password, rememberMe }) => async (dispatch) => {
  const data = {
    grant_type: GRANT_TYPE,
    username: username,
    password: password,
    client_id: APP_CLIENT_ID,
    scope: APP_SCOPE
  }

  try {
    const response = await api.postAuth(endpoints.authentication.signIn, data);

    const getSiteAdminDataResponse = await api.get(endpoints.common.siteAdmin);
    if (response && response.status === 200 && getSiteAdminDataResponse && getSiteAdminDataResponse.status === 200) {
      const decoded = jwt_decode(response.data.access_token);
      const user = {
        accountId: decoded.sub,
        email: decoded.email,
        role: decoded.role,
        givenName: decoded.given_name,
        username: decoded.preferred_username,
        sessionEndTime: decoded.exp,
        accessToken: response.data.access_token,

      };
      const isSiteLocked = getSiteAdminDataResponse?.data.isSiteLocked;
      if(isSiteLocked === 'true' && !user?.role.includes(SUPER_ADMIN)) {
        return dispatch(signInFail('The backend service is unavailable at the moment. Please try again later.'));
      }
      setSession(user, rememberMe);
      dispatch(userInfo(user));
      return dispatch(signInSuccess(user));
    }
    else {
      if(response?.data?.error_description === BACKEND_USER_INACTIVITY_MESSAGE){
        return dispatch(signInFail(USER_INACTIVITY_MESSAGE));
      }
      return dispatch(signInFail(response?.data?.error_description || 'Something was wrong with your login details. Please try again.'));
    }
  } catch (error) {
    return dispatch(signInFail(error?.data?.error_description || 'Something was wrong with your login details. Please try again.'));
  }
};

const signInSuccess = (user) => {
  return {
    type: actionTypes.SIGN_IN_SUCCESS,
    payload: user
  };
};

const signInFail = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: response
  };
};

export const signUp = (userInfo) => async (dispatch) => {
  const data = {
    "AppName": APP_SCOPE,
    "EmailAddress": userInfo.email,
    "extraProperties": {
      "FirstName": userInfo.firstName,
      "LastName": userInfo.lastName,
      "CompanyOrOrganisation": 'N/A',
      "Country": userInfo.country,
      "PostCode": userInfo.postCode,
      "FarmName": userInfo.farmName,
      "HoldingNumber": userInfo.holdingNumber,
      "BusinessRefNumber": userInfo.refNumber,
      "IsFarmAdvisory": userInfo.advisoryService,
      "ReasonForUsing": 'N/A',
      "OptInNewsletter": userInfo.allowExtraEmails,
      "AgreedToTandS": userInfo.termsCondAgreed
    }
  }
  try {
    const response = await api.post(endpoints.authentication.signUp, data);
    if (response.status === 200) {
      return dispatch(signUpSuccess("You are almost ready to user Agrecalc. First of all you must verify your email address. An email has been sent to the email address you entered. Please visit your email to complete your registration."));
    }
    else {
      return dispatch(signUpFail(response?.data?.error?.message || 'Something was wrong. Please try again.'));
    }
  } catch (error) {
    return dispatch(signUpFail(error?.data?.error?.message || 'Something was wrong. Please try again.'));
  }
};

const signUpSuccess = (msg) => {
  return {
    type: actionTypes.RESPONSE_STATE_SUCCESS,
    payload: msg
  };
};

const signUpFail = (error) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: error
  };
};

export const forgotPassword = (email) => async (dispatch) => {
  try {
    let returnUrl = `${window.location.hostname}/auth/login`;
    const response = await api.post(endpoints.authentication.forgotPassword,
      {
        email: email,
        appName: APP_SCOPE,
        returnUrl: returnUrl,
        returnUrlHash: ""

      });
    if (response.status === 204)
      return dispatch(forgotPasswordSuccess(response.data.message ||
        "An account recovery email has been sent to your e-mail address. If you do not receive the email within 15 minutes, check your spam/junk folder."));
    else
      return dispatch(forgotPasswordFail(response?.data?.error?.message || 'Something was wrong. Please try again.'));
  } catch (error) {
    return dispatch(forgotPasswordFail(error?.data?.error?.message || 'Something was wrong. Please try again.'));
  }
};

const forgotPasswordSuccess = (res) => {
  return {
    type: actionTypes.RESPONSE_STATE_SUCCESS,
    payload: res
  };
};
const forgotPasswordFail = (error) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: error
  };
};

export const resetPassword = ({ userId, resetToken, password }) => async (dispatch) => {
  try {
    const response = await api.post(endpoints.authentication.resetPassword, { userId, resetToken, password });// To Do: change to post when API ready
    if (response.status === 204){
      return dispatch(resetPasswordSuccess('Your password is successfully reset.'));
    }
    else{
      if(response?.data?.error?.message === 'Invalid token.'){
        return dispatch(resetPasswordFail('The provided link has expired. To re-issue the link reset your password on the "Forgot password?" page.'));
      }
      return dispatch(resetPasswordFail(response?.data?.error?.message || 'Something was wrong. Please try again.'));
    }
  } catch (error) {
    return dispatch(resetPasswordFail(error?.data?.error?.message || 'Something was wrong. Please try again.'));
  }
};

const resetPasswordSuccess = (res) => {
  return {
    type: actionTypes.RESPONSE_STATE_SUCCESS,
    payload: res
  };
};
const resetPasswordFail = (error) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: error
  };
};


export const signOut = () => async (dispatch) => {
  deleteSession();
  return dispatch(signOutSuccess());
};

const signOutSuccess = () => {
  return {
    type: actionTypes.SIGN_OUT,
  };
};

export const userInfo = (user) => {
  return {
    type: actionTypes.SET_USER,
    payload: user
  };
};

export const resetAuthResponse = (user) => {
  return {
    type: actionTypes.RESET_AUTH_RESPONSE
  };
};


export const getUserInfo = () => async (dispatch) => {
  const response = await api.get(endpoints.user.info);
  try {
    if (response && response.status === 200) {
      const { defaultFarm, officeId, organisationId, roleNames } = response?.data;
      const commonUser = store.getState().common.user;
      commonUser.role = roleNames[0];
      dispatch(userInfo(commonUser));
      dispatch(setFarmId(defaultFarm));
      return dispatch(getUserInfoSuccess({ officeId, organisationId }));
    }
    else {
      return dispatch(getUserInfoFail(response?.data?.error?.message || 'Something was wrong with your login details. Please try again.'));
    }
  } catch (error) {
    return dispatch(getUserInfoFail(response?.data?.error?.message || 'Something was wrong with your login details. Please try again.'));
  }
}

export const getUserPermissions = () => async (dispatch) => {
  const response = await api.get(endpoints.user.permissions);
  try {
    if (response && response.status === 200) {
      const permissions = response?.data;
      return dispatch(getUserPermissionsSuccess(permissions));
    }
    else {
      return dispatch(getUserPermissionsFail(response?.data?.error?.message || 'Something went wrong while retrieving user permissions. Please try again.'));
    }
  } catch (error) {
    return dispatch(getUserInfoFail(response?.data?.error?.message || 'Something went wrong while retrieving user permissions. Please try again.'));
  }
}

export const getUserPermissionsForFarm = (params) => async (dispatch) => {
  const response = await api.get(endpoints.user.farmPermissions.replace('FARM_ID', params?.farmId));
  try {
    if (response && response.status === 200) {
      const permissions = response?.data;
      return dispatch(getUserPermissionsForFarmSuccess(permissions));
    }
    else {
      return dispatch(getUserPermissionsForFarmFail(response?.data?.error?.message || 'Something went wrong while retrieving user permissions. Please try again.'));
    }
  } catch (error) {
    return dispatch(getUserInfoFail(response?.data?.error?.message || 'Something went wrong while retrieving user permissions. Please try again.'));
  }
}

const setFarmId = (user) => {
  return {
    type: actionTypes.SET_FARM_ID,
    payload: user
  };
}

const getUserInfoSuccess = (user) => {
  return {
    type: actionTypes.GET_USER_INFO_SUCCESS,
    payload: user
  };
}

const getUserInfoFail = (user) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: user
  };
}

const getUserPermissionsSuccess = (user) => {
  return {
    type: actionTypes.GET_USER_PERMISSIONS_SUCCESS,
    payload: user
  };
}

const getUserPermissionsFail = (user) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: user
  };
}

const getUserPermissionsForFarmSuccess = (user) => {
  return {
    type: actionTypes.GET_USER_PERMISSIONS_FOR_FARM_SUCCESS,
    payload: user
  };
}

const getUserPermissionsForFarmFail = (user) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: user
  };
}
