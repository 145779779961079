import { Grid, AppBar, Tab, FormControl, MenuItem, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import dayjs from 'dayjs';
import { FormDescriptionTypography, FormHeaderPaper, FormTitleTypography,  
         FarmDetailsSelect, InfoTooltip,ResultsTableCell,
         ResultTablesTabs, ResultsTableRow, ResultsTableTitleTypography,
         ResultsProdHeadingTableCell,ResultsFooter } from '../../components/CustomStyles/StyledComponents';
import DataChecksErrors from '../../components/Errors/DataChecksErrors';
import { getEnterpriseSectorList, setLoading, setReportId, unsetLoading, getAllReports } from '../../store/appAction';
import SelectedFarm from '../../components/SelectedFarm/SelectedFarm';
import { endpoints } from '../../api/endpoints';
import * as api from '../../api/base';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { addCommas } from '../../helpers/stringFormatHelper';
import { Cyrb53 } from '../../helpers/hashHelper';
import PropTypes from 'prop-types';


function sortProductsByName(a,b){
    // Orders are : Meat + Wool (sheep) , Milk + Meat (dairy), Eggs + Meat (laying hens), Grain + Straw (cereals, oilseeds etc)
    // small dataset so we use array but for larger list change to map
    const order = ['milk','eggs','meat','wool','grain','straw']
    const aIdx=order.indexOf(a?.productName.toLowerCase())
    const bIdx=order.indexOf(b?.productName.toLowerCase())
    return aIdx-bIdx
}

// ------------------------------------------------------------------------------------------------
const EnterpriseProdComparisonRow = ({rowHeader, activeRpt, compareRptList, fieldName, productIdx }) => {
    
    let sxObj={ width: `${100/(compareRptList?.length+2)}%`}  
    return (
    <ResultsTableRow>
        <ResultsTableCell sx={sxObj}>{rowHeader}</ResultsTableCell>
        {
            compareRptList?.map((entProd,index) => (
                <ResultsTableCell sx={sxObj} key={Cyrb53(rowHeader, index)}>
                    {addCommas(entProd?.enterpriseEmission?.enterpriseProducts?.[productIdx]?.[fieldName]) ||  '--'}
                </ResultsTableCell>
            )) 
        }
        <ResultsTableCell sx={sxObj}>
            {addCommas(activeRpt?.enterpriseEmission?.enterpriseProducts?.[productIdx]?.[fieldName]) || '--'}
        </ResultsTableCell>
    </ResultsTableRow>
    )
}
EnterpriseProdComparisonRow.propTypes = {
    rowHeader: PropTypes.string,
    activeRpt: PropTypes.object,
    compareRptList: PropTypes.array,
    fieldName:PropTypes.string,
    productIdx: PropTypes.number

};

// ------------------------------------------------------------------------------------------------
const ComparisonRow = ({rowHeader, activeRpt, compareRptList, rptCategoryField, fieldName }) => {
    
    // iterate over previous years (compareRptList)
    //  and at the end put the current report(activeRpt)
    let sxObj={ width: `${100/(compareRptList?.length+2)}%`}
    return (
    <ResultsTableRow>
        <ResultsTableCell sx={sxObj}>{rowHeader}</ResultsTableCell>
        {
            compareRptList?.map((rpt,index) => (
                <ResultsTableCell sx={sxObj} key={Cyrb53(rowHeader, index)}>
                    {addCommas(rpt?.[rptCategoryField]?.[fieldName]) ||  '--'}
                </ResultsTableCell>
            ))
            
        }
        <ResultsTableCell sx={sxObj}>
            {addCommas(activeRpt?.[rptCategoryField]?.[fieldName]) || '--'}
        </ResultsTableCell>
    </ResultsTableRow>
    )
}

ComparisonRow.propTypes = {
    rowHeader: PropTypes.string,
    activeRpt: PropTypes.object,
    compareRptList: PropTypes.array,
    rptCategoryField: PropTypes.string,
    fieldName: PropTypes.string
};

// ------------------------------------------------------------------------------------------------
const ComparisonTableRows = ({ tableFieldLst, tableData }) => {
    return( tableFieldLst.map((entry, index) => (
                <ComparisonRow key={Cyrb53(entry.header + entry.dataField, index)}
                    rowHeader={entry.header}
                    activeRpt={tableData?.activeReport} compareRptList={tableData?.comparisonReportResultDtos}
                    rptCategoryField={entry.categoryField} fieldName={entry.dataField} />
            ))
    )
}
ComparisonTableRows.propTypes = {
    tableFieldLst: PropTypes.array,
    tableData: PropTypes.object
}

// ------------------------------------------------------------------------------------------------
const ComparisonTable = ({ tableHeader, enterpriseName, tableFieldLst, tableData, tableFooterLst }) => {
    let sxObj={ width: `${100/(tableData?.comparisonReportResultDtos?.length+2)}%`}
    return (      
        <TableContainer sx={{ mb: 3 }}>
            {tableHeader && <ResultsTableTitleTypography sx={{ mb: 2 }}>{tableHeader}</ResultsTableTitleTypography> }

            <Table>
                <TableHead>
                    <TableRow>
                        <ResultsTableCell sx={sxObj}/>
                        {
                            tableData?.comparisonReportResultDtos?.map((report,index) => {
                                const reportYear = dayjs(report?.yearEnd).format('YYYY');
                                return (
                                    <ResultsTableCell sx={sxObj} key={Cyrb53(`${enterpriseName} ${reportYear}`, index)} >
                                        {`${enterpriseName} ${reportYear} (kg CO₂-eq)`}
                                    </ResultsTableCell>
                                );
                            })
                        }
                        <ResultsTableCell sx={sxObj}>
                            {`${enterpriseName} ${dayjs(tableData?.activeReport?.yearEnd).format('YYYY')} (kg CO₂-eq)`}
                        </ResultsTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <ComparisonTableRows tableFieldLst={tableFieldLst} tableData={tableData} />
                </TableBody>
                {tableFooterLst && 
                    <ResultsFooter>
                        <ComparisonTableRows tableFieldLst={tableFooterLst} tableData={tableData} />
                    </ResultsFooter>
                }

            </Table>
        </TableContainer>
    )
}
ComparisonTable.propTypes = {
    tableHeader: PropTypes.string,
    enterpriseName: PropTypes.string,
    tableFieldLst: PropTypes.array,
    tableData: PropTypes.object,
    tableFooterLst: PropTypes.array,
}

// ------------------------------------------------------------------------------------------------
const summaryTbl = {
    body:[  {header:'Direct CO₂', categoryField:'emissionSummary',dataField:'directCo2'},
            {header:'Indirect CO₂', categoryField:'emissionSummary',dataField:'indirectCo2'},
            {header:'Methane', categoryField:'emissionSummary',dataField:'methane'},
            {header:'Nitrous Oxide', categoryField:'emissionSummary',dataField:'nitrousOxide'},
    ],
    footer:[{header:'Total Emissions', categoryField:'emissionSummary',dataField:'totalEmission'},
    ]
}
const SummaryTable = ({ tableData, selectedEnterpriseName }) => {
    return selectedEnterpriseName &&
            <ComparisonTable tableHeader={"Emissions Summary Table"} enterpriseName={selectedEnterpriseName}
            tableFieldLst={summaryTbl.body}
            tableData={tableData}
            tableFooterLst={summaryTbl.footer}/>
}
SummaryTable.propTypes = {
    tableData: PropTypes.object,
    selectedEnterpriseName: PropTypes.string
}

// ------------------------------------------------------------------------------------------------
const totalEmissionsTbl ={
    body:[  {header:'Gross emissions from farming', categoryField:'emissionDetailed',dataField:'grossEmissionFromFarming'},
            {header:'Farm Area', categoryField:'emissionDetailed',dataField:'farmArea'},
            {header:'Whole farm CO₂ emissions per kg of farm output', categoryField:'emissionDetailed',dataField:'wholeFarmCo2EmissionPerFarmOutput'},
            {header:'Emissions per hectare', categoryField:'emissionDetailed',dataField:'emissionPerHectare'},
            {header:'Farm and enterprise output', categoryField:'emissionDetailed',dataField:'farmAndEnterpriseOutput'}
        ],
        footer:null
    }   
const carbonSeqTbl = {
    body:[{header:'Biochar', categoryField:'emissionDetailed',dataField:'bioCharSequestration'},
          {header:'Hedges', categoryField:'emissionDetailed',dataField:'hedgeSequestration'},
          {header:'Forestry', categoryField:'emissionDetailed',dataField:'forestrySequestration'},
    ],
    footer:null
}
const nitrousOxideTbl ={
    body: [ {header:'Imported fertilisers and manures', categoryField:'emissionDetailed',dataField:'importedFertilisersAndManure'},
            {header:'Grazing, manure spreading, and manure management', categoryField:'emissionDetailed',dataField:'grazingManSpreadingAndManManagement'},
            {header:'Crop residues', categoryField:'emissionDetailed',dataField:'cropResidues'}
        ], 
    footer: [ {header:'Total nitrous oxide', categoryField:'emissionDetailed',dataField:'totalNitrousOxide'},      
    ]
}
const methaneTbl = {
    body:[  {header:'Feed digestion (GWP100)', categoryField:'emissionDetailed',dataField:'feedDigestion'},
            {header:'Manure management (GWP100)', categoryField:'emissionDetailed',dataField:'manureManagement'}       
    ],
    footer:[{header:'Total methane (GWP100)', categoryField:'emissionDetailed',dataField:'totalMethane'},
            {header:'Total methane (GWP*)', categoryField:'emissionDetailed',dataField:'totalMethaneFromGwp'}
    ]
}
const indirectCO2Tbl = {
    body:[{header:'Fertiliser', categoryField:'emissionDetailed',dataField:'fertiliser'},
          {header:'Lime', categoryField:'emissionDetailed',dataField:'lime'},
          {header:'Pesticides', categoryField:'emissionDetailed',dataField:'pesticides'},
          {header:'Bedding', categoryField:'emissionDetailed',dataField:'bedding'},
          {header:'Feed', categoryField:'emissionDetailed',dataField:'feed'},
          {header:'Energy', categoryField:'emissionDetailed',dataField:'energy'},
          {header:'Plastic use / disposal', categoryField:'emissionDetailed',dataField:'plasticUse'},
          {header:'Carcass disposal', categoryField:'emissionDetailed',dataField:'carcassDisposal'},
          {header:'Transport', categoryField:'emissionDetailed',dataField:'transport'},
          {header:'Biochar', categoryField:'emissionDetailed',dataField:'bioChar'}
    ],
    footer:[{header:'Total indirect carbon dioxide (other)', categoryField:'emissionDetailed',dataField:'totalIndirectCo2'}]
}
const directCO2Tbl = {
    body:[{header:'Diesel', categoryField:'emissionDetailed',dataField:'diesel'},
          {header:'Electricity', categoryField:'emissionDetailed',dataField:'electricity'},
          {header:'Other fuels', categoryField:'emissionDetailed',dataField:'otherFuels'},
          {header:'Renewable heat and electricity', categoryField:'emissionDetailed',dataField:'renewableHeatAndElectricity'},
    ],
    footer:[{header:'Total direct carbon dioxide', categoryField:'emissionDetailed',dataField:'totalDirectCo2'}]
}

const detailedTables= [
    {tblHdr:"Direct Carbon Dioxide", tblData:directCO2Tbl},
    {tblHdr:"Indirect Carbon Dioxide (Farm inputs)", tblData:indirectCO2Tbl},
    {tblHdr:"Methane", tblData:methaneTbl},
    {tblHdr:"Nitrous Oxide", tblData:nitrousOxideTbl},
    {tblHdr:"Carbon Sequestration", tblData:carbonSeqTbl}
]

const DetailedTable = ({ tableData, selectedEnterpriseName, option }) => {
    const activeReportEnterpriseEmission = tableData?.activeReport?.enterpriseEmission?.enterpriseProducts || null;
    activeReportEnterpriseEmission?.sort(sortProductsByName)
    
    // sort the products 
    tableData?.comparisonReportResultDtos?.forEach((report) => 
        report?.enterpriseEmission?.enterpriseProducts?.sort(sortProductsByName)
    );

    return <>
        {selectedEnterpriseName && <> 
            {detailedTables.map((tbl,index)=>(
                <ComparisonTable key={Cyrb53(`${tbl.tblHdr}-${index}`,index)} 
                    tableHeader={tbl.tblHdr} enterpriseName={selectedEnterpriseName}
                    tableFieldLst={tbl.tblData.body}
                    tableData={tableData}
                    tableFooterLst={tbl.tblData.footer}/>
                ))
            }
            {option.title === 'Whole Farm' && 
            <ComparisonTable tableHeader={"Total Emissions"}  enterpriseName={selectedEnterpriseName}
                             tableFieldLst={totalEmissionsTbl.body} tableData={tableData} tableFooterLst={totalEmissionsTbl.footer}/>
            }
            {activeReportEnterpriseEmission !== null &&
                 <TableContainer sx={{ mb: 3 }}>
                    <ResultsTableTitleTypography sx={{ mb: 2 }} style={{ paddingBottom: '5px', marginBottom: '5px' }}>{"Product Emissions"}</ResultsTableTitleTypography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <ResultsTableCell sx={{ width: '25%' }}></ResultsTableCell>
                                {
                                    tableData?.comparisonReportResultDtos?.map((report) => {
                                        const reportYear = dayjs(report?.yearEnd).format('YYYY');
                                        return (
                                            <ResultsTableCell sx={{ width: '25%' }}> {`${selectedEnterpriseName} ${reportYear}`} </ResultsTableCell>
                                        );
                                    })
                                }
                                <ResultsTableCell sx={{ width: '25%' }}> {`${selectedEnterpriseName} ${dayjs(tableData?.activeReport?.yearEnd).format('YYYY')}`} </ResultsTableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {activeReportEnterpriseEmission?.map((product, index) => {
                                let widthPct=`${100/(tableData?.comparisonReportResultDtos?.length+2)}%`
                                return (<>
                                    <TableRow>
                                        <ResultsProdHeadingTableCell sx={{ width: widthPct }}>
                                            {product?.productName}
                                        </ResultsProdHeadingTableCell>
                                        {tableData?.comparisonReportResultDtos?.map((e,i) => {
                                            return <ResultsProdHeadingTableCell key={Cyrb53(`${product?.productName}-${index}`, index)} sx={{ width: widthPct }} />
                                        })}
                                        <ResultsProdHeadingTableCell sx={{ width: widthPct }}/>
                                    </TableRow>

                                    <EnterpriseProdComparisonRow rowHeader="Product Total Emissions"
                                        activeRpt={tableData?.activeReport}
                                        compareRptList={tableData?.comparisonReportResultDtos}
                                        fieldName={'totalEmissions'}
                                        productIdx={index} />
                                    <EnterpriseProdComparisonRow rowHeader="Emissions per kg of product output"
                                        activeRpt={tableData?.activeReport}
                                        compareRptList={tableData?.comparisonReportResultDtos}
                                        fieldName={'emissionPerKgOfProduct'}
                                        productIdx={index} />
                                </>
                                );
                            })
                            }
                        </TableBody>
                        <ResultsFooter>
                        <ComparisonRow rowHeader="Emissions per hectare"
                                    activeRpt={tableData?.activeReport}
                                    compareRptList={tableData?.comparisonReportResultDtos}
                                    rptCategoryField='enterpriseEmission'
                                    fieldName={'emissionPerHectare'} />
                                {/* There is a bug in the backend. This need to be the KG of product not the whole
                                    enterprise emissions */
                                    <ComparisonRow rowHeader="Enterprise output"
                                    activeRpt={tableData?.activeReport}
                                    compareRptList={tableData?.comparisonReportResultDtos}
                                    rptCategoryField='enterpriseEmission'
                                    fieldName={'enterpriseOutput'}/>
                                }


                        </ResultsFooter>
                    </Table>
                </TableContainer>
            }
        </>}
    </>
}

DetailedTable.propTypes = {
    tableData: PropTypes.object,
    selectedEnterpriseName: PropTypes.string,
    option: PropTypes.object,
}

const YearOnYear = () => {
    const dispatch = useDispatch();
    const reportId = useSelector(state => state.common.reportId);
    const reportList = useSelector(state => state.farm.reports);
    const enterpriseList = useSelector(state => state.enterprise.enterpriseList);
    const farmId = useSelector(state => state.common.farmId);
    const adminFarm = useSelector(state => state.adminFarm);


    const [results, setResults] = useState(undefined);
    const [currentTab, setCurrentTab] = useState(0);
    const [enterpriseOptIdx, setEnterpriseOptIdx] = useState(undefined);
    const [option, setOption] = useState({});

    useEffect(() => {
        dispatch(getAllReports({ farmId }));
    }, []);

    const getData = async (option) => {
        dispatch(setLoading());
        const params = {};
        if (option.title === 'Whole Farm') {
            params.reportId = reportId;
            const response = await api.get(endpoints.yearOnYear.yearOnYearData.concat('?', queryString.stringify(params)));
            if (response && response.status === 200) {
                setResults(response?.data || {});
                dispatch(unsetLoading());
            }

        } else {
            if (option.reportEnterpriseSectorItemId !== null) {
                //livestock == false
                params.reportId = reportId;
                params.cropEnterpriseId = option?.reportEnterpriseSectorItemId;

            } else {
                //livestock = true
                params.reportId = reportId;
                params.livestockEnterpriseId = option?.reportEnterpriseSectorGroupId
            }
            const response = await api.get(endpoints.yearOnYear.yearOnYearData.concat('?', queryString.stringify(params)));
            if (response && response.status === 200) {
                setResults(response?.data || {});
                dispatch(unsetLoading());
            }
        }
    }

    useEffect(() => {
        (enterpriseList.length > 0) && handleEnterpriseSelect({ target: { value: '' } });
    }, [enterpriseList]);


    useEffect(() => {
        dispatch(getEnterpriseSectorList({ reportId }));
        setOption({});
        setEnterpriseOptIdx(undefined);
    }, [reportId]);

    useEffect(() => {
        console.log("option", "pass");
        getData(option);
    }, [option]);

    const handleReportSelect = (e) => {
        dispatch(setReportId(e.target.value));
    }

    const handleEnterpriseSelect = async e => {
        if (e.target.value === '') {
            const enterprise = {
                calculatorReportVersion: null,
                title: "Whole Farm",
                reportEnterpriseSectorId: null,
                reportEnterpriseSectorGroupId: null,
                reportEnterpriseSectorItemId: null
            }
            await setOption(enterprise);

        }
        setEnterpriseOptIdx(e.target.value);
        const enterprise = enterpriseList[e.target.value];
        if (enterprise) {
            await setOption(enterprise);
        }
    };

    const handleTabChange = (event, tab) => {
        setCurrentTab(tab);
    };

    return (
        <>
            {results?.status === 1 ?
                <DataChecksErrors errorList={results.dataChecks} /> :
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                        <FormHeaderPaper
                            sx={{
                                p: 0,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >

                            <SelectedFarm farmId={farmId} farmName={adminFarm.farmDetails.farmName} />
                            <FormTitleTypography variant="h5" component="div">
                                Year on Year Results
                            </FormTitleTypography>
                            <FormDescriptionTypography variant="body2" paragraph>
                                {'Detailed and summary results for the whole farm and per enterprise will be compared to results from the previous two years. If you wish to compare year on year results from another scenario, select the report from the Report options below.'}
                            </FormDescriptionTypography>
                        </FormHeaderPaper>
                    </Grid>

                    <Grid item xs={12}>
                        <AppBar position="static">
                            <ResultTablesTabs
                                value={currentTab}
                                onChange={handleTabChange}
                                indicatorColor="primary"
                                variant="standard"
                                aria-label="full width tabs example"
                                autoFocus={true}
                            >
                                <Tab sx={{ textTransform: 'none' }} label="Summary" autoFocus={true} />
                                <Tab sx={{ textTransform: 'none' }} label="Detailed" />
                            </ResultTablesTabs>
                        </AppBar>
                    </Grid>

                    <Grid item xs={12} mb={2}>
                        <Grid container direction="row" spacing={1}>
                            <Grid item lg={4} md={6} sm={12} xs={12}>
                                <FormControl variant="outlined" sx={{ width: '99%', float: 'left' }}>
                                    <b style={{ fontSize: '1.15rem' }}>Report</b>
                                    <FarmDetailsSelect
                                        displayEmpty
                                        value={reportId || ''}
                                        onChange={handleReportSelect}
                                        autoFocus={true}
                                    >
                                        {reportList.map((option) => (
                                            <MenuItem
                                                id={option.reportId} key={option.reportId} value={option.reportId}>{option.reportName}</MenuItem>
                                        ))}
                                    </FarmDetailsSelect>
                                </FormControl>

                            </Grid>

                            <Grid item lg={4} md={6} sm={12} xs={12}>
                                <FormControl
                                    variant="outlined"
                                    sx={{ width: '91%', float: 'left' }}
                                >
                                    <b style={{ fontSize: '1.15rem' }}>Enterprise</b>
                                    <FarmDetailsSelect
                                        displayEmpty
                                        value={enterpriseOptIdx !== undefined ? enterpriseOptIdx : ''}
                                        onChange={handleEnterpriseSelect}
                                    >
                                        <MenuItem value=""><em>Whole Farm</em></MenuItem>
                                        {enterpriseList.map((option, index) => (
                                            <MenuItem
                                                id={index}
                                                key={index}
                                                value={index}
                                            >
                                                {option.title}
                                            </MenuItem>
                                        ))}
                                    </FarmDetailsSelect>
                                </FormControl>
                                <InfoTooltip
                                    placement="bottom-end"
                                    title="Select one of your reports and then whether you want to see the figures for the whole farm breakdown or just for an enterprise">
                                    <InfoOutlinedIcon color='primary' sx={{ marginLeft: '2px', marginTop: '32px', }} fontSize='small' />
                                </InfoTooltip>
                            </Grid>
                        </Grid>
                    </Grid >

                    <Grid item xs={12} md={12} lg={12} sx={{ minHeight: 300 }}>
                        {
                            results && currentTab === 0 ?
                                <SummaryTable tableData={results} selectedEnterpriseName={option?.title}/> :
                                <DetailedTable tableData={results} selectedEnterpriseName={option?.title} option ={option}/>
                        }
                    </Grid>
                </Grid>
            }
        </>
    )
}

export default YearOnYear