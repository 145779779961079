import { Card, CardActionArea, CardContent, FormControl, Grid, MenuItem, Paper, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { FormDescriptionTypography, FormHeaderPaper, FormTitleTypography, InfoTooltip } from '../../../components/CustomStyles/StyledComponents';
import DataChecksErrors from '../../../components/Errors/DataChecksErrors';
import CircularPieChart from '../../../components/Charts/CircularPieChart';
import ColoredPieChart from '../../../components/Charts/ColoredPieChart';
import VerticalBarsChart from '../../../components/Charts/VerticalBarsChart';
import { addCommas } from '../../../helpers/stringFormatHelper';
import { ResultStatus } from '../../../helpers/reportResultsHelper';
import { getEnterpriseCompareToList, getWholeFarmChart, getFarmReport,
         setLoading, setReportId, unsetLoading, getAllReports, getUserPermissionsForFarm } from '../../../store/appAction';
import SelectedFarm from '../../../components/SelectedFarm/SelectedFarm';
import ResultsReportsBar from '../../../components/Results/ResultsReportsBar';
import { FARMER } from '../../../constants/userRoles';
import { endpoints } from '../../../api/endpoints';
import * as api from '../../../api/base';

const emptyChartData = {
    "status": ResultStatus.NotCalculated,
    "dataChecks": null
}

const WholeFarmCharts = () => {
    const dispatch = useDispatch();
    
    const chartData = useSelector(state => state.wholeFarm.chartData);
    const reportId = useSelector(state => state.common.reportId);
    const reportDetails = useSelector(state => state.farmReport.reportDetails);
    const reportList = useSelector(state => state.farm.reports);
    const farmId = useSelector(state => state.common.farmId);
    const adminFarm = useSelector(state => state.adminFarm);
    const user = useSelector(state => state.auth.user);

    const [results, setResults] = useState(emptyChartData);
    const [selectedFarmName, setSelectedFarmName] = useState(null);
    const [calcStatus, setCalcStatus] = useState(ResultStatus.NotCalculated);


    useEffect(() => {
        dispatch(getAllReports({ farmId }));
        dispatch(getUserPermissionsForFarm({ farmId }));
    }, [dispatch,farmId]);

    useEffect(() => {
        dispatch(setLoading());
        dispatch(getFarmReport({ reportId }));
        dispatch(getWholeFarmChart({ reportId }));
        dispatch(getEnterpriseCompareToList({ reportId }));
        if (user.role === FARMER) {
            getFarmNameForFarmer(farmId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportId]);

    useEffect(() => {
        setResults(chartData || emptyChartData);
        if(chartData.dataChecks || chartData.error){
            setCalcStatus(ResultStatus.Error)
        }
        setTimeout(() => {
            dispatch(unsetLoading());
        }, 400);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartData]);

    useEffect(() => {
        console.log(reportDetails)
        setCalcStatus(reportDetails.calculationStatus);
        setTimeout(() => {
            dispatch(unsetLoading());
        }, 400);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportDetails]);

    const getFarmNameForFarmer = async (farmId) => {
        if (farmId) {
            const response = await api.get(endpoints.admin.farm.farm.concat('?farmId=', farmId));
            if (response && response.status === 200) {
                setSelectedFarmName(response.data.farmName);
            }
        }
    }

    const handleReportSelect = (e) => {
        dispatch(setReportId(e.target.value));
    }
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
                <FormHeaderPaper
                    sx={{
                        p: 0,
                        display: 'flex',
                        flexDirection: 'column',
                    }}>

                    <SelectedFarm farmId={farmId} farmName={adminFarm.farmDetails.farmName || selectedFarmName} />
                    <FormTitleTypography variant="h5" component="div">
                        Resource use & Emissions Results
                    </FormTitleTypography>
                    <FormDescriptionTypography variant="body2" paragraph>
                        {'Emissions by enterprise, source and gas for the whole farm are presented below. To see a more detailed tabular view select Whole Farm - Tables in the menu above.'}
                    </FormDescriptionTypography>
                </FormHeaderPaper>
            </Grid>
            <Grid item xs={12} mb={2}>
                <ResultsReportsBar handleReportSelect={handleReportSelect}  reportList={reportList} reportId={reportId} calcStatus={calcStatus}/>
            </Grid>
            {results?.dataChecks || calcStatus!==ResultStatus.Completed || results?.emissionByEnterprise === undefined || results?.emissionBySource===undefined?
                    <DataChecksErrors errorList={results?.dataChecks}  resultStatus={reportDetails.calculationStatus} /> :
                    <>
                        <Grid item xs={12} mb={3}>
                            <Grid container direction='row' spacing={1}>
                                <Grid item xs={12} sm={6} md={3} >
                                    <Card sx={{ textAlign: 'center', height: '100%' }} variant='outlined'>
                                        <CardActionArea sx={{ height: '100%' }}>
                                            <CardContent sx={{ padding: '10px' }}>
                                                <Typography gutterBottom variant="h6" component="div">
                                                    Total Emissions:
                                                </Typography>
                                                {results && <Typography variant="h6" fontWeight="bold" component="div" color="#cc5500">
                                                    {addCommas(results.totalEmissions)}
                                                </Typography>}
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} >
                                    <Card sx={{ textAlign: 'center', height: '100%' }} variant='outlined'>
                                        <CardActionArea sx={{ height: '100%' }}>
                                            <CardContent sx={{ padding: '10px' }}>
                                                <Typography gutterBottom variant="h6" component="div">
                                                    Highest Enterprise:
                                                </Typography>
                                                {results && <Typography variant="h6" fontWeight="bold" component="div" color="#cc5500">
                                                    {results.highestCarbonEnterprise}
                                                </Typography>}
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} >
                                    <Card sx={{ textAlign: 'center', height: '100%' }} variant='outlined'>
                                        <CardActionArea sx={{ height: '100%' }}>
                                            <CardContent sx={{ padding: '10px' }}>
                                                <Typography gutterBottom variant="h6" component="div">
                                                    Largest Source:
                                                </Typography>
                                                {results && <Typography variant="h6" fontWeight="bold" component="div" color="#cc5500">
                                                    {results.largestSource}
                                                </Typography>}
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} >
                                    <Card sx={{ textAlign: 'center', height: '100%' }} variant='outlined'>
                                        <CardActionArea sx={{ height: '100%' }}>
                                            <CardContent sx={{ padding: '10px' }}>
                                                <Typography gutterBottom variant="h6" component="div">
                                                    Highest Gas Emission:
                                                </Typography>
                                                {results && <Typography variant="h6" fontWeight="bold" component="div" color="#cc5500">
                                                    {results.highestGasEmission}
                                                </Typography>}
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} mb={5}>
                            {results && <Grid container direction='row' spacing={2}>
                                <Grid item xs={12} sm={12} md={4}>
                                    <Paper variant='outlined'>
                                        <ColoredPieChart key={1} title='Emissions by Enterprise' dataset={results.emissionByEnterprise} />
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} >
                                    <Paper variant='outlined'>
                                        <VerticalBarsChart key={2} title='Emissions by Source' 
                                        emissionUnit='CO₂-eq Emissions (%)'
                                        axisInfo= {{x:[{name:'Your Farm',dataKey:'value'}], xUnit:'%', y: 'title'}}
                                        dataset={results.emissionBySource}/>
                                    </Paper>
                                </Grid>
                                <Grid item  xs={12} sm={12} md={4} >
                                    <Paper variant='outlined'>
                                        <CircularPieChart key={3} title='Emissions by Gas' dataset={results.emissionByGas}/>
                                    </Paper>
                                </Grid>
                            </Grid>}
                        </Grid>
                    </>
            }
        </Grid >
    )
}

export default WholeFarmCharts