
export const agcColours = Object.freeze({
    AgrecalcGreen: ' #309F34',
    PrussianBlue: ' #253764',
    AgrecalcTiffanyBlue: ' #91EDE4', 
    AgrecalcPetroleum: ' #007788', 
    AgrecalcForestGreen: ' #004E37',
    AgrecalcBurntOrange: ' #CC5500',
    AgrecalcBarley: ' #F1B435',
    AgrecalcButtercup: ' #FBE122', 
    MilanoRed: ' #A13430',
    AgrecalcPetroleum60:' #00D4F4',
    AgrecalcForestGreen80: ' #008A61'

});

export const AGCColourSets = Object.freeze({
    Primary: [ agcColours.AgrecalcGreen,
               agcColours.PrussianBlue
    ],
    Secondary:[ agcColours.AgrecalcTiffanyBlue,
                agcColours.AgrecalcPetroleum,
                agcColours.AgrecalcForestGreen
    ],
    Tertiary:[  agcColours.AgrecalcBurntOrange,
                agcColours.AgrecalcBarley,
                agcColours.AgrecalcButtercup,
                agcColours.MilanoRed
    ],
    DarkMode: [ agcColours.AgrecalcTiffanyBlue,
                agcColours.AgrecalcBarley,
                agcColours.AgrecalcButtercup,
    ],
    Contrasting: [agcColours.AgrecalcBarley,agcColours.PrussianBlue,agcColours.AgrecalcTiffanyBlue,
                  agcColours.MilanoRed,agcColours.AgrecalcButtercup,agcColours.AgrecalcForestGreen,
                  agcColours.AgrecalcBurntOrange,agcColours.AgrecalcPetroleum,agcColours.AgrecalcGreen,
                  agcColours.AgrecalcPetroleum60,agcColours.AgrecalcForestGreen80],
    BarCharts: [agcColours.PrussianBlue, agcColours.AgrecalcBurntOrange]
});

const textContrast = new Map([[agcColours.AgrecalcTiffanyBlue,agcColours.PrussianBlue],
[agcColours.AgrecalcButtercup,agcColours.PrussianBlue],
[agcColours.AgrecalcBarley,agcColours.PrussianBlue]]);

export const PieChartColours = Object.freeze({
    //returns the colour for a pie chart based on our branding colors
    getColour: (index)=> { return AGCColourSets.Contrasting[index % AGCColourSets.Contrasting.length] },
    getLabelTextColour: (fillcolour) =>{ return textContrast.get(fillcolour) || '#FFFFFF'}
});

export const BarChartColours = Object.freeze({
    getColour: (index)=> { return AGCColourSets.BarCharts[index % AGCColourSets.BarCharts.length] },
}); 