import { isNumber, isFinite } from "lodash";

export const addCommas = (value, ignoreZero=true, decimals=2) => {
    // return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace(/CO2/, "CO₂");
    if(ignoreZero && value===0){
        return 0
    }else if(isFinite(value) && !Number.isInteger(value)){
        value=value.toFixed(decimals)
    }
    return value?.toString().replace(/^[+-]?\d+/, (int) => {
        return int.replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }).replace(/CO2/, "CO₂");
}

export const m3Superscript = (value) => {
    return value?.replace(/m3/, "m³");
}

export const removeCommas = (value) => {
    return isNumber(value) ? value : value?.replace(/,/g, "");
}

export const replaceEmptyStringWithNull = (obj) =>  {
    if (typeof obj !== 'object' || obj === null) {
        if (obj === '') {
            return null;
        }
        return obj;
    }
    if (Array.isArray(obj)) {
        return obj.map(item => replaceEmptyStringWithNull(item));
    }
    const newObj = {};
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            newObj[key] = replaceEmptyStringWithNull(obj[key]);
        }
    }
    return newObj;
  }