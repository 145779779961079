import React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import { Autocomplete, ListItem, ListItemButton, Paper, Select, Tabs, Tooltip, tooltipClasses, Divider } from '@mui/material';
import AppBar from '@mui/material/AppBar'
import Button from '@mui/material/Button'
import { DatePicker } from '@mui/x-date-pickers';
import agrecalcGrass from '../../assets/images/grass.jpg'
import agrecalcField from '../../assets/images/field.jpg'


export const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

export const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

export const ResultTablesTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiButtonBase-root': {
    fontSize: '1rem !important',
  },
  '& .Mui-selected': {
    fontWeight: 700,
    color: `${theme.palette.text.primary} !important`,
  }
}));

export const ListTableCell = styled(TableCell)(({ theme , color = `${theme.palette.text.primary}` }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.text.primary,
    borderBottom: '2px solid',
    fontWeight: 'bold',
    padding: '2px 2px !important',

  },
  fontSize: '.87rem',
  fontWeight: 'normal',
  borderColor: 'transparent',
  textAlign: 'left',
  padding: '2px 6px !important',
  color: color

}));

export const SearchTableCell = styled(ListTableCell)(({ theme }) => ({
  padding: '2px 2px !important',
}));


export const ResultsProdHeadingTableCell = styled(TableCell)(({ theme , color = `${theme.palette.text.primary}` }) => ({
  backgroundColor: theme.palette.primary.contrastText,
  color: theme.palette.text.primary,
  borderBottom: '1px dotted',
  fontWeight: 'bold',
  fontSize: '.9rem',
  textAlign: 'left',
  alignItems: 'left'
}));

export const ResultsTableCell = styled(TableCell)(({ theme , color = `${theme.palette.text.primary}` }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.text.primary,
    borderBottom: '2px solid',
    fontWeight: 'bold'
  },
  borderWidth: 0,
  fontWeight: 'normal',
  borderColor: 'transparent',
  textAlign: 'right',
  fontSize: '.9rem',
  borderRadius: 0,
  color:color
}));

export const ResultsFooter = styled(TableFooter)(({ theme , color = `${theme.palette.text.primary}` }) => ({
  backgroundColor: theme.palette.primary.contrastText,
  borderBottom: '1px solid',
  borderTop: '1px solid',
  fontWeight: 'normal',
  color:color
}));

export const ResultsTableCellWithoutMargin = styled(TableCell)(({ theme , color = `${theme.palette.text.primary}` }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.text.primary,
    borderBottom: '2px solid',
    fontWeight: 'bold'
  },
  [`&.${tableCellClasses.footer}`]: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.text.primary,
    borderBottom: '1px solid',
  },
  borderWidth: 0,
  fontWeight: 'normal',
  borderColor: 'transparent',
  textAlign: 'right',
  fontSize: '.9rem',
  borderRadius: 0,
  color:color
}));

export const ResultsTableCellWithMargin = styled(TableCell)(({ theme , color = `${theme.palette.text.primary}` }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.text.primary,
    borderBottom: '2px solid',
    fontWeight: 'bold'
  },
  [`&.${tableCellClasses.footer}`]: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.text.primary,
    borderTop: '1px solid',
  },
  borderWidth: 0,
  fontWeight: 'normal',
  borderColor: 'transparent',
  textAlign: 'right',
  fontSize: '.9rem',
  borderRadius: 0,
  color:color,
  borderTop: '1px solid',
}));

export const ResultsTableCellPDF = styled(ResultsTableCell)(({ theme , color = `${theme.palette.text.primary}` }) => ({
  [`&.${tableCellClasses.head}`]: {
    borderBottom: '1px solid',
    fontWeight: '500'
  },
  height: 32,
  fontSize: '11px',
  padding: '2px 4px',
  color : color
}));

export const WholeFarmSummaryTableCellPDF = styled(ResultsTableCell)(({ theme }) => ({
  padding: '4px 8px',
  border: '1px solid',
  textAlign: 'left',
}));

export const FormTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#91EDE4',
    color: theme.palette.text.primary,
    fontSize: '.95rem'
  },
  fontSize: '.9rem'
}));

export const ListTableRow = styled(TableRow)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.secondary.main,
  }
}));

export const ResultsTableRow = styled(TableRow)(({ theme }) => ({
  'td:first-of-type': {
    textAlign: 'left',
  },
  'th:first-of-type': {
    textAlign: 'left',
  },
}));

export const FormRowGroupTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: /*theme.palette.secondary.main*/'#D6F8F5',
  'td:only-child': {
    textAlign: 'left',
    backgroundColor: /*theme.palette.secondary.main*/'#D6F8F5',
    // borderBottom: `1px solid ${theme.palette.primary.dark}`,
  },
  // 'tr:last-child': {
  //     borderBottom: `1px solid ${theme.palette.primary.dark}`,
  // },
}));

export const FormTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: /*theme.palette.secondary.main*/'#D6F8F5',
  },
  '&:nth-of-type(even)': {
    backgroundColor: /*theme.palette.secondary.light*/'#F4FDFC',
  },
  'td:first-of-type': {
    textAlign: 'left',
  },
}));

export const FormHeaderPaper = styled(Paper)(({ theme, divider = true }) => {
  if(divider){
    return ({
      borderBottom: '1px solid',
      borderBottomColor: theme.palette.secondary.dark,
      borderRadius: 0,
      padding: '0 !important'
    })
  }
  return ({
    padding: '0 !important',
  })
});

export const CustomizedDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.secondary.dark,
  marginBottom:'5px',
  marginTop:'5px'
}));

export const FormTitleTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 500
}));

export const HeaderFooterTypographyPDF = styled(Typography)(({ theme }) => ({
  fontSize: '11px',
  opacity: .75
}));

export const ReportFormTitleTypography = styled(FormTitleTypography)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

export const FormDescriptionTypography = styled(Typography)(({ theme }) => ({
  fontSize: '1.05rem'
}));

export const ActiveFarmTypography = styled(Typography)(({ theme }) => ({
  fontSize: '1.2rem'
}));

export const FormTitleTypographyPDF = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  fontWeight: 500,
  color: theme.palette.primary.main,
}));

export const DocumentTitleTypographyPDF = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const FormDescriptionTypographyPDF = styled(Typography)(({ theme }) => ({
  fontSize: '14px'
}));

export const ParagraphTypographyPDF = styled(Typography)(({ theme }) => ({
  fontSize: '11px'
}));

export const CaptionTypographyPDF = styled(Typography)(({ theme }) => ({
  fontSize: '8px'
}));

export const SectorTitleTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  padding: '20px 8px 0px',
  // borderBottom: '1px solid',
  // borderColor: theme.palette.primary.main,
  width: ' inherit',
  fontWeight: 700,
  fontSize: '1.05rem'
}));

export const AltSectorTitleTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: '34px 8px 0px',
  // borderBottom: '1px solid',
  // borderColor: theme.palette.primary.main,
  width: ' inherit',
  fontWeight: 700,
  fontSize: '1.05rem'
}));

export const SectorTableTitleTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  width: ' inherit',
  fontWeight: 700,
  fontSize: '1.00rem'
}));

export const ChartTitleTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  color: theme.palette.primary.dark,
  fontSize: '17px',
  marginBottom: '2px'
}));

export const ResultsTableTitleTypography = styled(AltSectorTitleTypography)(({ theme }) => ({
  color: theme.palette.primary.main
}));

export const ReportEnterpriseGroupTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: '15px 0px 0px',
  width: ' inherit',
  fontWeight: 700
}));

export const ReportClassesTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  padding: '15px 0px 0px',
  width: ' inherit',
  fontWeight: 700
}));

export const RegisterInfoTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  marginBottom: 8
}));

export const PrimaryListItemTypography = styled(Typography)(({ theme }) => ({
  '::marker': {
    content: '"\\25CF\\2800"'
  }
}));

export const SecondaryListItemTypography = styled(Typography)(({ theme }) => ({
  '::marker': {
    content: '"\\25CB\\2800"'
  }
}));

export const TableListItem = styled(ListItem)(({ theme }) => ({
  paddingTop: '2px'
}));

export const DataEntryTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-input': {
    fontSize: '.8rem !important',
    padding: '4px 6px !important',
  },
  '& .Mui-error': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: 2,
      border: '2px #d32f2f solid'
    },
    '& .MuiOutlinedInput-input': {
      backgroundColor: '#f9cdcd',
    }
  }
}));

export const DataEntryAutocomplete = styled(Autocomplete)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    fontSize: '.8rem !important',
    padding: '0px 6px !important',
  }
}));

export const DataEntrySelect = styled(Select)(({ theme }) => ({
  '& .MuiOutlinedInput-input': {
    fontSize: '.8rem !important',
    padding: '2px 6px !important',
  }
}));

export const FarmDetailsSelect = styled(Select)(({ theme }) => ({
  color: theme.palette.text.primary,
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.text.primary,
  },
  '& .MuiOutlinedInput-input': {
    fontSize: '.95rem !important',
    padding: '2px 6px !important',
    paddingRight: '30px !important'
  }
}));

export const DataEntryDatePicker = styled(DatePicker)(({ theme }) => ({
  '& .MuiOutlinedInput-input': {
    fontSize: '.8rem !important',
    padding: '4px 6px !important',
    paddingRight: '2px !important'
  },
  '& .MuiIconButton-root': {
    padding: '6px !important',
    marginRight: '-16px',
  },
  '& .MuiInputAdornment-root': {
    marginLeft: '-4px'
  },
  '.MuiSvgIcon-root': {
    fontSize: '1rem'
  }
  //MuiButtonBase-root-MuiIconButton-root
}));

export const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  '& .MuiListItemText-root': {
    '& .MuiTypography-root': {
      color: theme.palette.text.primary,
      fontSize: '1.2rem'
    }
  },
  '&.Mui-selected': {
    '& .MuiListItemText-root': {
      '& .MuiTypography-root': {
        fontWeight: 700,
      }
    }
  },
}));


export const AuthAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: 'transparent',
  boxShadow: 'none'
}));

export const AuthButton = styled(Button)(({ theme }) => ({
  fontWeight: 700,
  backgroundColor: '#309f34',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#216f24',
    color: '#FFFFFF',
  },
}));

export const LoginPaper = styled(Paper)(({ theme }) => ({
  backgroundImage: `url(${agrecalcGrass})`,
  paddingTop: '20px',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  borderRadius: 0
}));

export const RegisterPaper = styled(Paper)(({ theme }) => ({
  backgroundImage: `url(${agrecalcField})`,
  paddingTop: '20px',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  borderRadius: 0
}));

export const AuthTypography = styled(Typography)(({ theme }) => ({
  color: '#FFFFFF',
  fontSize: "20px"
}));

export const MyFarmPaper = styled(Paper)(({ theme }) => ({
  padding: '20px',
  backgroundSize: 'cover',
  borderRadius: 0,
  width: 'auto',
}));

export const InfoTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#def5df',
    color: theme.palette.text.primary,
    maxWidth: 350,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(12),
    // border: '1px solid',
    // borderColor: theme.palette.text.primary
  },
}));

export const OpportunityLevelInfoTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#def5df',
    color: theme.palette.text.primary,
    minWidth: 520,
    maxWidth: 350,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(12),
    // border: '1px solid',
    // borderColor: theme.palette.text.primary
  },
}));

export const InfoTooltipForGWP = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#def5df',
    color: theme.palette.text.primary,
    maxWidth: 480,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(12),
    marginRight: 20
  },
}));

export const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#def5df',
    color: theme.palette.text.primary,
    maxWidth:500,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(12),
    marginRight: 20
  },
}));

export const NoMaxWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#def5df',
    color: theme.palette.text.primary,
    maxWidth:'none',
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(12),
    marginRight: 20
  },
}));

export const PageHeaderPaper = styled(Paper)(({ theme }) => ({
  borderBottom: '1px solid',
  borderBottomColor: '#ababab',
  borderRadius: 0,
}));

export const PageFooterPaper = styled(Paper)(({ theme }) => ({
  borderTop: '1px solid',
  borderTopColor: '#ababab',
  borderRadius: 0,
}));

