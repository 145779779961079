import {  Card, CardActionArea, CardContent, FormControl, Grid, MenuItem, Paper, Typography } from '@mui/material'
import _ from 'lodash';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { FarmDetailsSelect, FormDescriptionTypography, FormHeaderPaper, FormTitleTypography, InfoTooltip } from '../../../components/CustomStyles/StyledComponents'
import DataChecksErrors from '../../../components/Errors/DataChecksErrors';
import CircularPieChart from '../../../components/Charts/CircularPieChart';
import ColoredPieChart from '../../../components/Charts/ColoredPieChart';
import VerticalBarsChart from '../../../components/Charts/VerticalBarsChart';
import { addCommas } from '../../../helpers/stringFormatHelper';
import { table2ChartData } from '../../../helpers/reportResultsHelper';
import { getEnterpriseChart, getEnterpriseTable, getEnterpriseCompareToList,
         getEnterpriseSectorList, setLoading, setReportId, unsetLoading } from '../../../store/appAction';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CreatePDFButton from '../../../components/Buttons/CreatePDFButton';
import SelectedFarm from '../../../components/SelectedFarm/SelectedFarm';

const EnterpriseCharts = () => {
    const dispatch = useDispatch();
    const tableData = useSelector(state => state.enterprise.tableData);
    const chartData = useSelector(state => state.enterprise.chartData);
    const enterpriseList = useSelector(state => state.enterprise.enterpriseList);
    const compareToList = useSelector(state => state.enterprise.compareToList);
    const reportId = useSelector(state => state.common.reportId);
    const reportList = useSelector(state => state.farm.reports);
    const farmId = useSelector(state => state.common.farmId);
    const adminFarm = useSelector(state => state.adminFarm);
    const permissions = useSelector(state => state.auth.permissions);

    const [chartsResults, setChartResults] = useState(undefined);
    const [results, setResults] = useState([]);
    const [compareTo, setCompareTo] = useState({});
    const [option, setOption] = useState({});
    const [enterpriseOptIdx, setEnterpriseOptIdx] = useState(undefined);
    const [compareToOptIdx, setCompareToOptIdx] = useState(undefined);

    useEffect(() => {
        // dispatch(getEnterpriseSectorList({ reportId }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(setLoading());
        dispatch(getEnterpriseSectorList({ reportId }));
        dispatch(getEnterpriseCompareToList({ reportId }));
        setCompareTo({});
        setOption({});
        setEnterpriseOptIdx(undefined);
        setCompareToOptIdx(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportId]);

    useEffect(() => {
        (enterpriseList.length > 0) && handleEnterpriseSelect({ target: { value: 0 } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enterpriseList]);

    const getData = ()=>{
        dispatch(setLoading());
        dispatch(getEnterpriseChart({
            ReportId: reportId,
            ReportEnterpriseSectorId: option.reportEnterpriseSectorId,
            ReportEnterpriseSectorGroupId: option.reportEnterpriseSectorGroupId,
            ReportEnterpriseSectorItemId: option.reportEnterpriseSectorItemId,
            ReportComparisonType: compareTo?.reportComparisonType,
            ComparisonReferenceId: compareTo?.referenceId,
        }));
        // Tabular data is used in the pie charts
        dispatch(getEnterpriseTable({
            ReportId: reportId,
            ReportEnterpriseSectorId: option.reportEnterpriseSectorId,
            ReportEnterpriseSectorGroupId: option.reportEnterpriseSectorGroupId,
            ReportEnterpriseSectorItemId: option.reportEnterpriseSectorItemId,
            ReportComparisonType: compareTo?.reportComparisonType,
            ComparisonReferenceId: compareTo?.referenceId,
        }));
    }

    useEffect(() => {
        if (compareToOptIdx !== undefined && enterpriseOptIdx !== undefined) {
            getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [compareTo]);

    useEffect(() => {
        if (!_.isEmpty(option) && enterpriseOptIdx !== undefined) {
            getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [option]);

    useEffect(() => {
        setChartResults(chartData || {});
        dispatch(unsetLoading());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartData]);


    useEffect(() => {
        setResults(tableData || {});
        dispatch(unsetLoading());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableData]);


    const handleReportSelect = (e) => {
        dispatch(setReportId(e.target.value));
    }

    const handleEnterpriseSelect = async e => {
        setEnterpriseOptIdx(e.target.value);
        const enterprise = enterpriseList[e.target.value];
        if (enterprise) {
            await setOption(enterprise);
        }
    };

    const handleCompareToSelect = async e => {
        setCompareToOptIdx(e.target.value);
        const compareTo = compareToList[e.target.value];
        if (compareTo) {
            await setCompareTo(compareTo);
        }else{
            await setCompareTo({});
        }
    };



    return (
        <>
            {chartsResults?.status === 1 ?
                <DataChecksErrors errorList={chartsResults.dataChecks} /> :
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                        <FormHeaderPaper sx={{ p: 0, display: 'flex',flexDirection: 'column',}}>
                            <SelectedFarm farmId={farmId} farmName={adminFarm.farmDetails.farmName}/>
                            <FormTitleTypography variant="h5" component="div">
                                Enterprise Results
                            </FormTitleTypography>
                            <FormDescriptionTypography variant="body2" paragraph>
                                {'Emissions by source and gas per report and enterprise are presented below. You can also compare your farm against another. To view this information in more detailed tabular format please select Enterprise - Table in the menu above.'}
                            </FormDescriptionTypography>
                        </FormHeaderPaper>
                    </Grid>
                    <Grid item xs={12} mb={2}>
                        <Grid container direction="row">
                            <Grid item lg={4} md={6} sm={12} xs={12} pr={1}>
                                <FormControl variant="outlined" sx={{ width: '99%', float: 'left' }} >
                                    <b style={{ fontSize: '1.15rem' }}>Report</b>
                                    <FarmDetailsSelect displayEmpty value={reportId || ''} onChange={handleReportSelect} autoFocus={true}>
                                        <MenuItem value=""><em>Change Report...</em></MenuItem>
                                        {reportList.map((option) => (
                                            <MenuItem id={option.reportId} key={option.reportId} value={option.reportId}>{option.reportName}</MenuItem>
                                        ))}
                                    </FarmDetailsSelect>
                                </FormControl>

                            </Grid>
                            <Grid item lg={4} md={6} sm={12} xs={12} pr={1}>
                                <FormControl variant="outlined" sx={{ width: '99%', float: 'left' }} >
                                    <b style={{ fontSize: '1.15rem' }}>Enterprise</b>
                                    <FarmDetailsSelect displayEmpty value={enterpriseOptIdx !== undefined ? enterpriseOptIdx : ''} onChange={handleEnterpriseSelect}>
                                        <MenuItem value=""><em>Select enterprise...</em></MenuItem>
                                        {enterpriseList.map((option, index) => (
                                            <MenuItem
                                                id={index}
                                                key={index}
                                                value={index}
                                            >{option.title}</MenuItem>
                                        ))}
                                    </FarmDetailsSelect>
                                </FormControl>
                            </Grid>
                            <Grid item lg={4} md={6} sm={12} xs={12}>
                                {
                                    Object.values(permissions ?? {}).some(value => ['AgreCalc.Benchmarks'].includes(value)) && 
                                    <>
                                        <FormControl
                                            variant="outlined"
                                            sx={{ width: '91%', float: 'left' }}
                                        >
                                        <b style={{ fontSize: '1.15rem' }}>Compare To</b>
                                        <FarmDetailsSelect
                                            displayEmpty
                                            value={compareToOptIdx !== undefined ? compareToOptIdx : ''}
                                            onChange={handleCompareToSelect}
                                        >
                                        <MenuItem value=""><em>Select report...</em></MenuItem>
                                        {compareToList.map((option, index) => (
                                            <MenuItem
                                                id={index}
                                                key={index}
                                                value={index}
                                            >
                                                {option.itemTitle}
                                            </MenuItem>
                                        ))}
                                    </FarmDetailsSelect>
                                </FormControl>
                                <InfoTooltip
                                    placement="bottom-end"
                                    title="Select one of your reports and an enterprise sector group belonging to it.  Then compare to another enterprise report with the same sector or an industry benchmark">
                                    <InfoOutlinedIcon color='primary' sx={{ marginLeft: '2px', marginTop: '32px', }} fontSize='small' />
                                </InfoTooltip>
                                    </>
                                }
                            </Grid>
                            <Grid item lg={12} md={6} sm={12} xs={12} sx={{ textAlign: 'right' }} >
                                <CreatePDFButton
                                    referrer={'enterprise'}
                                    options={{ ...option, ...compareTo }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} mb={3}>
                        <Grid container direction='row' spacing={1}>
                            <Grid item xs={12} md={3}>
                                <Card sx={{ textAlign: 'center', height: '100%' }} variant='outlined'>
                                    <CardActionArea sx={{ height: '100%' }}>
                                        <CardContent sx={{ padding: '10px' }}>
                                            <Typography gutterBottom variant="h6" component="div">
                                                Total Emissions:
                                            </Typography>
                                            {chartsResults && <Typography variant="h6" fontWeight="bold" component="div" color="#cc5500">
                                                {addCommas(chartsResults.totalEmissions)}
                                            </Typography>}
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Card sx={{ textAlign: 'center', height: '100%' }} variant='outlined'>
                                    <CardActionArea sx={{ height: '100%' }}>
                                        <CardContent sx={{ padding: '10px' }}>
                                            <Typography gutterBottom variant="h6" component="div">
                                                Product Emissions:
                                            </Typography>
                                            {chartsResults && <Typography variant="h6" fontWeight="bold" component="div" color="#cc5500">
                                                {addCommas(chartsResults.productEmissions)}
                                            </Typography>}
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Card sx={{ textAlign: 'center', height: '100%' }} variant='outlined'>
                                    <CardActionArea sx={{ height: '100%' }}>
                                        <CardContent sx={{ padding: '10px' }}>
                                            <Typography gutterBottom variant="h6" component="div">
                                                Largest Source:
                                            </Typography>
                                            {chartsResults && <Typography variant="h6" fontWeight="bold" component="div" color="#cc5500">
                                                {chartsResults.largestSource}
                                            </Typography>}
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Card sx={{ textAlign: 'center', height: '100%' }} variant='outlined'>
                                    <CardActionArea sx={{ height: '100%' }}>
                                        <CardContent sx={{ padding: '10px' }}>
                                            <Typography gutterBottom variant="h6" component="div">
                                                Highest Gas Emission:
                                            </Typography>
                                            {chartsResults && <Typography variant="h6" fontWeight="bold" component="div" color="#cc5500">
                                                {chartsResults.highestGasEmission}
                                            </Typography>}
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} mb={5}>
                        {chartsResults && <Grid container direction='row' spacing={2}>
                            <Grid item xs={12} md={4}>
                                <Paper variant='outlined'>
                                    <ColoredPieChart keyID={4} title='Emissions by Source' dataset={table2ChartData(results)?.emissionSource} />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Paper variant='outlined'>
                                    <VerticalBarsChart keyID={5} title='Emissions vs Comparison' dataset={chartsResults?.emissionVsComparison} emissionUnit={chartsResults?.emissionUnit} />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Paper variant='outlined'>
                                    <CircularPieChart keyID ={6} title='Emissions by Gas' dataset={chartsResults?.emissionByGas} />
                                </Paper>
                            </Grid>
                        </Grid>}
                    </Grid>
                </Grid >
            }
        </>
    )
}

export default EnterpriseCharts