import { Grid, Paper } from '@mui/material'
import React, { } from 'react'
import { FormHeaderPaper, FormTitleTypographyPDF, ParagraphTypographyPDF } from '../../../../components/CustomStyles/StyledComponents';

const Contents = ({ results = {} }) => {
    return (
        <Paper sx={{ maxWidth: '860px', pageBreakBefore: 'always', pageBreakAfter: 'always', marginTop: '5mm'}} p={2} >
            <Grid item xs={12} mb={5}>
                <FormHeaderPaper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <FormTitleTypographyPDF fontSize={20} variant="h6" component="div" mb={1}>
                        Mitigation measures
                    </FormTitleTypographyPDF>
                    <Grid container direction="column">
                        <Grid item >
                            <Grid container direction="row">
                                <Grid item xs={4} sx={{ display: 'inline-flex', }}>
                                    <ParagraphTypographyPDF variant="body2" paragraph fontWeight="bold" mb={0}>
                                        {`Mitigation area`}
                                    </ParagraphTypographyPDF>
                                </Grid>
                                <Grid item xs={8} sx={{ display: 'inline-flex', }}>
                                    <ParagraphTypographyPDF variant="body2" paragraph fontWeight="bold" mb={0}>
                                        {`Actions`}
                                    </ParagraphTypographyPDF>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item >
                            <Grid container direction="row" mb={2}>
                                <Grid item xs={4} sx={{ display: 'inline-flex', }}>
                                    <ParagraphTypographyPDF variant="body2" mb={0}>
                                        {`Energy and fuels`}
                                    </ParagraphTypographyPDF>
                                </Grid>
                                <Grid item xs={8} sx={{}}>
                                    <ParagraphTypographyPDF variant="body2" mb={0}>
                                        {`Install smart meter to monitor electricity use – assess efficiency of equipment and activities.`}
                                    </ParagraphTypographyPDF>
                                    <ParagraphTypographyPDF variant="body2" mb={0}>
                                        {`Use thermostats, time clocks, motion sensors and low energy bulbs, increase lagging on hot water pipes, 
                                        reduce number of hot washes in dairy and renew milk pump or other equipment.`}
                                    </ParagraphTypographyPDF>
                                    <ParagraphTypographyPDF variant="body2" mb={0}>
                                        {`Record fuel use per tractor and activity – assess efficiency of vehicles and operations.`}
                                    </ParagraphTypographyPDF>
                                    <ParagraphTypographyPDF variant="body2" mb={0}>
                                        {`Undertake regular machinery checks and maintenance, use correct tyre pressure, improve journey planning.`}
                                    </ParagraphTypographyPDF>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" mb={2}>
                                <Grid item xs={4} sx={{ display: 'inline-flex', }}>
                                    <ParagraphTypographyPDF variant="body2" mb={0}>
                                        {`Renewable energy`}
                                    </ParagraphTypographyPDF>
                                </Grid>
                                <Grid item xs={8} sx={{}}>
                                    <ParagraphTypographyPDF variant="body2" mb={0}>
                                        {`Undertake a renewable energy feasibility study.`}
                                    </ParagraphTypographyPDF>
                                    <ParagraphTypographyPDF variant="body2" mb={0}>
                                        {`Consider installing a wind turbine, an anaerobic digester, developing farm-scale micro hydro electricity, 
                                        using a combined heat and power plant, growing trees as biomass fuel, using solar panels, ground source heat pumps or woodchip burners.`}
                                    </ParagraphTypographyPDF>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" mb={2}>
                                <Grid item xs={4} sx={{ display: 'inline-flex', }}>
                                    <ParagraphTypographyPDF variant="body2" mb={0}>
                                        {`Fertiliser and manure`}
                                    </ParagraphTypographyPDF>
                                </Grid>
                                <Grid item xs={8} sx={{}}>
                                    <ParagraphTypographyPDF variant="body2" mb={0}>
                                        {`Analyse soil and organic manure – ensure efficient use of organic and inorganic fertiliser.`}
                                    </ParagraphTypographyPDF>
                                    <ParagraphTypographyPDF variant="body2" mb={0}>
                                        {`Apply nitrogen at optimum rate and timing for crops, maintain clover content of swards, 
                                        consider covering slurry stores and injecting slurry.`}
                                    </ParagraphTypographyPDF>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" mb={2}>
                                <Grid item xs={4} sx={{ display: 'inline-flex', }}>
                                    <ParagraphTypographyPDF variant="body2" mb={0}>
                                        {`Livestock management`}
                                    </ParagraphTypographyPDF>
                                </Grid>
                                <Grid item xs={8} sx={{}}>
                                    <ParagraphTypographyPDF variant="body2" mb={0}>
                                        {`Increase livestock productivity.`}
                                    </ParagraphTypographyPDF>
                                    <ParagraphTypographyPDF variant="body2" mb={0}>
                                        {`Improve feed conversion efficiency, increase calving or lambing percentage, reduce mortalities, 
                                        increase weaning percentage, reduce age of calving, regularly review animal health plans, analyse silage or other homegrown forage.`}
                                    </ParagraphTypographyPDF>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" mb={2}>
                                <Grid item xs={4} sx={{ display: 'inline-flex', }}>
                                    <ParagraphTypographyPDF variant="body2" mb={0}>
                                        {`Locking carbon into the soil`}
                                    </ParagraphTypographyPDF>
                                </Grid>
                                <Grid item xs={8} sx={{}}>
                                    <ParagraphTypographyPDF variant="body2" mb={0}>
                                        {`Create carbon sinks.`}
                                    </ParagraphTypographyPDF>
                                    <ParagraphTypographyPDF variant="body2" mb={0}>
                                        {`Protect peatland and moorland from damage by avoiding over grazing, consider reduced tillage 
                                        and ploughing in stubble and other crop residues, control soil erosion, create wildlife corridors along water margins, 
                                        field margins and headlands, retain and conserve semi-natural grasslands, manage existing woodlands on farm and create new ones.`}
                                    </ParagraphTypographyPDF>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </FormHeaderPaper>
            </Grid>
            <Grid item xs={12}>
                <FormHeaderPaper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <FormTitleTypographyPDF fontSize={20} variant="h6" component="div" mb={1}>
                        Improve efficiency and environmental credentials
                    </FormTitleTypographyPDF>
                    <ParagraphTypographyPDF variant="body2" paragraph fontWeight="bold" mb={0}>
                        {`What does a carbon footprint actually tell you?`}
                    </ParagraphTypographyPDF>
                    <ParagraphTypographyPDF variant="body2" paragraph>
                        {`There is a strong correlation between efficiency, profitability and low carbon emissions.  The lower your carbon footprint, 
                        the more effective inputs have been at generating saleable product i.e. increased utilisation of costly inputs.  
                        Each farm and system have natural limitations but, within this context, the process can identify carbon ‘hotspots’  
                        on farm and is therefore a steer to improve efficiency and reduce greenhouse gas emissions.`}
                    </ParagraphTypographyPDF>
                    <ParagraphTypographyPDF variant="body2" paragraph fontWeight="bold" mb={0}>
                        {`How accurate does the information need to be?`}
                    </ParagraphTypographyPDF>
                    <ParagraphTypographyPDF variant="body2" paragraph>
                        {`The more accurate the information entered, the more meaningful the output.  
                        Where possible on-farm records should be used to provide accurate farm-level data.`}
                    </ParagraphTypographyPDF>
                </FormHeaderPaper>
            </Grid>
        </Paper>
    )
}

export default Contents;