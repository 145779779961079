import { createTheme } from "@mui/material";

export const mainTheme = createTheme(
  {
    palette: {
      type: 'light',
      primary: {
        contrastText: '#ffffff',
        main: '#309f34',
      },
      secondary: {
        main: '#d5eeed',
        dark: '#6AC2BF',
        light: '#F4FBFA',
        contrastText: '#EAF6F6',
      },
      text: {
        primary: '#253764',
        secondary: '#309f34',
      },
    },
    typography: {
      fontFamily: [
        'Montserrat'
      ].join(','),
    },
    agc:{
      text:{
        benchmarks:{
          low: '#C94D49',
          medium: '#EDA710',
          high: '#5ACE5D'
        }
      }
    },
    components: {
      MuiGrid: {
        styleOverrides: {
          root: {
            '&.deactivated': {
              pointerEvents: "none",
              opacity: .85
            },
            '&.activated': {
              pointerEvents: "all",
              opacity: 1
            } 
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: '.95rem',
            lineHeight: 1.25
          },
          '&.activated': {
            pointerEvents: "all",
            opacity: 1
          }
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
            backgroundColor: '#ffffff',
            color: '#309f34'
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: 'none'
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontSize: '.75rem',
            fontWeight: 'bold',
            lineHeight: 1.25,
            padding: '2px 8px',
            textAlign: 'center',
            borderStyle: 'solid',
            borderColor: '#ffffff',
            borderWidth: 2,
            height: 35,
            '& .MuiSvgIcon-root': {
              color: '#253764',
              right: '2px'
            }
          },
          head: {
            padding: '8px 8px',
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            margin: 2,
          },
          '&.activated': {
            pointerEvents: "all",
            opacity: 1
          }
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontWeight: '500',
            padding: 0
          },
          input: {
            padding: 0
          },
          '&.activated': {
            pointerEvents: "all",
            opacity: 1
          }
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
            '&.activated': {
              pointerEvents: "all",
              opacity: 1
            }
          },
          outlinedSecondary: {
            color: '#253764',
            backgroundColor: '#F4FBFA',
            border: '1px solid #253764',
            '&:hover': {
              border: '1px solid #253764',
              backgroundColor: '#d5eeed'
            },
          },
          endIcon: {
            marginLeft: 0,
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          colorSecondary: {
            color: '#253764',
            '&.Mui-checked': {
              color: '#253764',
            },
          },
          colorPrimary: {
            '&.Mui-checked': {
              color: '#ffffff',
            }
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            '&:hover, :focus': {
              backgroundColor: '#6AC2BF',
              color: '#ffffff'
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            backgroundColor: '#ffffff'
          },
          root: {
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#6AC2BF',
            },
          },
        },
      },
      MuiPopper: {
        styleOverrides: {
          root: {
            "& .MuiPaper-root": {
              backgroundColor: '#253764',
              borderRadius: 6,
              // border: '#309f34 1px solid',
              color: '#ffffff'
            },
            "& .MuiIconButton-root": {
              "&.MuiPickersCalendarHeader-switchViewButton": {
                color: '#309f34'
              },
              "&.MuiPickersArrowSwitcher-button": {
                color: '#309f34'
              },
            },
            '& .MuiTypography-root': {
              '&.MuiDayPicker-weekDayLabel': {
                color: '#253764',
                fontWeight: 700,
              },
            },
            '& .MuiDayPicker-header ': {
              marginBottom: '5px',
              backgroundColor: '#6AC2BF',
            },
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            backgroundColor: '#253764',
            borderRadius: 6,
            // border: '#309f34 1px solid',
            color: '#ffffff',
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              backgroundColor: '#253764',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
            '& .MuiSvgIcon-root': {
              color: '#ffffff'
            }
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            padding: 0,
          },
          select: {
            whiteSpace: 'normal',
          }
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            color: '#309f34',
            minHeight: 40,
            borderBottomColor: '#309f34',
            borderBottomStyle: 'solid',
            borderBottomWidth: 1,
            backgroundColor: 'transparent'
          },
          content: {
            margin: '8px 0'
          }
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            padding: '15px 0 30px',
            borderTop: '1px solid rgba(0, 0, 0, .125)',
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            border: 'none',
            width: 'inherit'
          },
        },
      },
      MuiListSubheader: {
        styleOverrides: {
          root: {
            top: 56,
            paddingLeft: 0,
            paddingRight: 0
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            paddingLeft: 0,
            paddingRight: 0
          },
        },
      },
      MuiSnackbar: {
        styleOverrides: {
          root: {
            top: '300px !important'
          },
        },
      },
    },
  }
);