import { Card, CardActionArea, CardContent, Grid, Paper, Typography } from '@mui/material'
import React, { } from 'react'
import { FormDescriptionTypographyPDF, FormTitleTypographyPDF } from '../../../../components/CustomStyles/StyledComponents';
import CircularPieChart from '../../../../components/Charts/CircularPieChart';
import ColoredPieChart from '../../../../components/Charts/ColoredPieChart';
import VerticalBarsChart from '../../../../components/Charts/VerticalBarsChart';
import { addCommas } from '../../../../helpers/stringFormatHelper';

const Charts = ({ results = {} }) => {
    return (
        <Paper p={2} sx={{ maxWidth: '860px', pageBreakAfter: 'always', marginTop: '5mm' }} >
            <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column', }}>
                <FormTitleTypographyPDF fontSize={20} variant="h6" component="div">
                    Whole Farm Emissions
                </FormTitleTypographyPDF>
                <FormDescriptionTypographyPDF variant="body2" fontSize={10} paragraph mb={0}>
                    {'This overview of your farm-level emissions provides a breakdown of your total greenhouse gas emissions by enterprise, source, and gas. This provides a quick overview of the total climate impact of your farm, and highlights major sources of emissions in your system.'}
                </FormDescriptionTypographyPDF>
            </Paper>
            <Grid container direction='row' mt={1}>
                <Grid item xs={3} p={1} >
                    <Card sx={{ textAlign: 'center', height: '100%' }} variant='outlined'>
                        <CardActionArea sx={{ height: '100%' }}>
                            <CardContent sx={{ padding: '5px' }}>
                                <Typography gutterBottom variant="h6" fontSize={14} component="div">
                                    Total Emissions:
                                </Typography>
                                {results && <Typography variant="h6" fontSize={15} fontWeight="bold" component="div" color="#cc5500">
                                    {addCommas(results.totalEmissions)}
                                </Typography>}
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={3} p={1}>
                    <Card sx={{ textAlign: 'center', height: '100%' }} variant='outlined'>
                        <CardActionArea sx={{ height: '100%' }}>
                            <CardContent sx={{ padding: '5px' }}>
                                <Typography gutterBottom variant="h6" fontSize={14} component="div">
                                    Highest Enterprise:
                                </Typography>
                                {results && <Typography variant="h6" fontSize={15} fontWeight="bold" component="div" color="#cc5500">
                                    {results.highestCarbonEnterprise}
                                </Typography>}
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={3} p={1} >
                    <Card sx={{ textAlign: 'center', height: '100%' }} variant='outlined'>
                        <CardActionArea sx={{ height: '100%' }}>
                            <CardContent sx={{ padding: '5px' }}>
                                <Typography gutterBottom variant="h6" fontSize={14} component="div">
                                    Largest Source:
                                </Typography>
                                {results && <Typography variant="h6" fontSize={15} fontWeight="bold" component="div" color="#cc5500">
                                    {results.largestSource}
                                </Typography>}
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={3} p={1}>
                    <Card sx={{ textAlign: 'center', height: '100%' }} variant='outlined'>
                        <CardActionArea sx={{ height: '100%' }}>
                            <CardContent sx={{ padding: '5px' }}>
                                <Typography gutterBottom variant="h6" fontSize={14} component="div">
                                    Highest Gas Emission:
                                </Typography>
                                {results && <Typography variant="h6" fontSize={15} fontWeight="bold" component="div" color="#cc5500">
                                    {results.highestGasEmission}
                                </Typography>}
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>

            <Grid container direction='row' mt={2} spacing={0}>
                <Grid item xs={6} pl={0}>
                    <Paper sx={{ pl: 0 }}>
                        <ColoredPieChart
                            isAnimationActive={false}
                            key={1}
                            title='Emissions by Enterprise'
                            dataset={results.emissionByEnterprise}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={6} pl={0}>
                    <Paper>
                        <CircularPieChart
                            isAnimationActive={false}
                            key={2}
                            title='Emissions by Gas'
                            dataset={results.emissionByGas}
                        />
                    </Paper>
                </Grid>
            </Grid>
            <Grid container direction='row' spacing={0}>
                <Grid item xs={12} pl={0}>
                    <Paper sx={{ width: '95%', margin: '0 auto', mt: 4 }}>
                        <VerticalBarsChart
                            isAnimationActive={false}
                            key={3}
                            title='Emissions by Source'
                            dataset={results.emissionBySource}
                            aspectRatio = {2.75}  />
                    </Paper>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default Charts