import React from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Label,
    ReferenceLine
} from "recharts";
import { Paper, Typography } from "@mui/material";
import _ from "lodash";
import { ChartTitleTypography } from "../CustomStyles/StyledComponents";
import { BarChartColours, AGCColourSets}  from "../../helpers/colourHelper"
import { Cyrb53 } from "../../helpers/hashHelper"

const renderCustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <Paper sx={{ p: 1 }} variant='outlined' >
                <Typography component='p' variant="caption">
                    {label}
                </Typography>
                {payload.map((p,idx) => (
                <Typography key={Cyrb53(`${p.name}-${p.value}`,idx)} component='p' variant="caption" 
                            fontWeight="bold" color={BarChartColours.getColour(idx)} >
                    {`${p.name} : ${p.value?.toFixed(2)}${p?.unit||''}`}
                </Typography>
                ))}
            </Paper>
        );
    }
    return null;
};

export default function VerticalBarsChart({
    title,
    emissionUnit,
    dataset, keyID,
    axisInfo = {
        x: [{name:'Your farm',dataKey:'value'},
            {name:'Comparison',dataKey:'comparison'}
        ],
        y: 'title'
    },
    colours = AGCColourSets.BarCharts,
    isAnimationActive = true,
    aspectRatio = 1.5
}) {

    return (
        <>
            <ChartTitleTypography gutterBottom align="center">
                {title}
            </ChartTitleTypography>
            <ResponsiveContainer aspect={aspectRatio}>
                <BarChart
                    isAnimationActive={isAnimationActive}
                    key={keyID}
                    data={_.filter(dataset, d => d.value >= 0)}
                    stackOffset="sign"
                    layout="vertical"
                    margin={{
                        top: 5,
                        right: 5,
                        left: 10,
                        bottom: 5
                    }}
                    barSize={8}
                >
                    {axisInfo.x.map((ax,idx)=>(
                        <XAxis name='Emissions' dataKey={ax.dataKey} type='number' key={Cyrb53(`${idx}-${ax.name}-${ax.dataKey}`,idx)}>
                            <Label value={emissionUnit} fontSize="1.2em" position="insideBottom" />
                        </XAxis>
                    ))}
                    <YAxis dataKey={axisInfo.y} type='category' interval={0} width={75} height={40} />
                    <Tooltip content={renderCustomTooltip} />
                    {axisInfo.x.length>1 &&  <Legend Height='10%'verticalAlign="bottom"/> }
                    <ReferenceLine x={0} stroke="#000" />
                    <CartesianGrid strokeDasharray="3 3" />
                    {axisInfo.x.map((ax,idx)=>(
                        <Bar name={ax.name} key={Cyrb53(`${idx}-${ax.name}-${ax.dataKey}`,idx)} dataKey={ax.dataKey} fill={colours[idx]} unit={axisInfo.xUnit ||''}  />
                    ))}
                </BarChart>
            </ResponsiveContainer>
        </>
    );
}
