import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import _, { isNull } from 'lodash';
import MuiAppBar from '@mui/material/AppBar';
import {  Box, Divider, IconButton,  styled, Toolbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import TopMenuList from './TopMenuList';
import ProfileMenu from './ProfileMenu';
import AboutMenu from './AboutMenu';
import { CONSULTANT } from '../../constants/userRoles';

const Header = ({
  drawerOpen,
  topMenuItems,
  subMenuItems,
  toggleDrawer,
}) => {

  const drawerWidth = 240;
  const location = useLocation();
  const user = useSelector(state => state.auth.user);
  const { reportDetails, reportConfig } = useSelector(state => state.farmReport);

  const [currentMenuIndex, setCurrentMenuIndex] = useState(undefined);

  useEffect(() => {
    const path = location.pathname?.split('/');
    let index = _.findIndex(subMenuItems, (menuItemList) => {
      return menuItemList && menuItemList.length > 0 && menuItemList[0].path.includes(path[2]);
    });
    setCurrentMenuIndex(index);
  }, [location, subMenuItems]);


  const showTopMenu = (menuItem, user) =>{
    let show = (menuItem.roles && menuItem.roles.includes(user.role)) || !menuItem.roles
    // Consultant without an org won't be able to create Users or Projects
    // TODO remove when we creat a role for Farmers with multiple Farms
    show = (user.role === CONSULTANT && ['Users','Projects'].includes(menuItem.title))?(show && !isNull(user.organisationId)): show
    return show
  }

  const getTopMenuItemList = () => {
    return (
      <>
        {
          topMenuItems.map((menuItem, index) => (
            showTopMenu(menuItem,user) &&
            <TopMenuList
              key={index}
              index={index}
              headerMenu={menuItem}
              subMenuList={subMenuItems[index]}
              isCurrentMenuIndex={currentMenuIndex === index}
              includeSoilCarbon={reportDetails?.includeSoilCarbon}
              landAndCropsOnly={reportConfig?.landAndCropsOnly}
              livestockOnly={reportConfig?.livestockOnly}
              soilCarbonOnly={!reportDetails?.includeSoilCarbon}
              includeBiochar={reportDetails?.includeBiochar}
              setCurrentMenuIndex={setCurrentMenuIndex}
            />
          ))
        }
      </>
    );
  }

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));
  
  return (
    <React.Fragment>
      <AppBar position="absolute" open={drawerOpen}>
        <Toolbar
          sx={{
            pr: '24px', // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: '36px',
              ...(drawerOpen && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          {getTopMenuItemList()}
          <Box sx={{ display: 'flex', alignItems: 'center', ml: 'auto' }}>
            {/* <IconButton sx={{ mr: 2 }} color="inherit">
                            <Badge badgeContent={4} color='secondary' >
                                <NotificationsIcon />
                            </Badge>
                        </IconButton> */}
            <Divider sx={{ mr: 2 }} orientation="vertical" flexItem />
            <ProfileMenu />
            <AboutMenu />         
          </Box>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
}


export default Header
