import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import toastr from "toastr";
import { FARMER } from '../../constants/userRoles';
import { toastrCustomOptionsLonger } from '../../constants/toastrOptions';
import queryString from 'query-string';
import ReportCompareToModal from '../Modal/ReportCompareToModal';

const CreatePDFButton = ({ referrer, options }) => {
    let navigate = useNavigate();
    const user = useSelector(state => state.auth.user);
    const farm = useSelector(state => ([FARMER].includes(user.role) ?
        state.profile.farm : state.adminFarm.farmDetails));
    const compareToList = useSelector(state => state.enterprise.compareToList);

    const [farmerFriendly, setFarmerFriendly] = useState(false);
    const [compareToIdx, setCompareToIdx] = useState(undefined);
    const [compareToModalOpen, setCompareToModalOpen] = useState(false);

    const handleCompareToSelect = e => {
        setCompareToIdx(e.target.value)
    };

    const handleSetFarmerFriendly = e => {
        setFarmerFriendly(e.target.checked)
    };

    const handleCompareToModalResponse = (positiveResponse) => {
        setCompareToModalOpen(false);
        if (positiveResponse) {
            navigate(`/results/${farmerFriendly ?
                'farmer-friendly' : referrer}/report-preview?${queryString.stringify({
                    compareTo: compareToList[compareToIdx]?.reportComparisonType,
                    reference: compareToList[compareToIdx]?.referenceId
                })}`);
        }
    }

    const handleOnClick = () => {
        if (/*farm.paid*/true) //TODO: implement the license module
            switch (referrer) {
                case 'enterprise':
                    navigate(`/results/${referrer}/report-preview?${queryString.stringify(options)}`);
                    break;
                case 'whole-farm':
                    setCompareToModalOpen(true);
                    break;
                default:
                    break;
            }
        else {
            toastr.warning(`You need to upgrade to use this feature.  
            Please contact <a href = "mailto: info@agrecalc.com">info@agrecalc.com</a> for more details.`,
                undefined, toastrCustomOptionsLonger);
        }
    }

    return (
        <>
            <Button
                variant="contained"
                sx={{ textTransform: 'none', marginTop: '23px' }}
                onClick={handleOnClick}>
                Create PDF
            </Button>
            <ReportCompareToModal
                isOpen={compareToModalOpen}
                compareToIdx={compareToIdx}
                farmerFriendly={farmerFriendly}
                handleResponse={handleCompareToModalResponse}
                handleCompareToSelect={handleCompareToSelect}
                handleSetFarmerFriendly={handleSetFarmerFriendly}
            />
        </>
    );
};

export default CreatePDFButton;

