import { getPageStyles } from './pageStyleHelper';
import dayjs from 'dayjs';


export  const cssPrintHeader = (farm,reportData,detailed) => { 
    return getPageStyles(`'agrecalc'`,
            `'${farm?.friendlyFarmId}: ${farm?.farmName} \\a `
            + `${reportData?.friendlyReportId}: ${reportData?.reportName}  \\a `
            + `Year End : ${dayjs(reportData?.yearEnd).format('MMM YYYY')}'`,
            `'Model : ${detailed?.calculatorReportVersion} \\a `
            + `Generated On: ${dayjs(reportData?.reportCreated || new Date()).format('YYYY-MM-DD')}'`
        )
}