export const getPageStyles = (hLeft=null, hCenter=null, hRight=null, bCenter = null) => {
    return `
    @media all {
        *CSS code*
    }

    @media print {
        table.print-friendly tr td, table.print-friendly tr th svg{
            page-break-inside: avoid;
            break-inside: avoid;
        }
        /* Define the header and footer */
        header {
            position: fixed;
            display: block;
            top: 0mm;
            left: 0;
            right: 0;
            height: 25mm;
        }    
        header::before {
            counter-increment: page; /* Increment the value of section counter by 1 */
        }
        body {
            counter-reset: page 1;
        }
        footer {
            position: fixed;
            display: block;
            bottom: 0;
            left: 0;
            right: 0;
            height: 10mm;
        }
    }
    @page { 
        size: A4;
        margin-top: 22mm;
        margin-bottom: 22mm;
        border-top-style: solid;
        border-bottom-style: solid;
        border-width: .75px;
        border-color: #a9a9a9;

        @top-left{
            content: ${hLeft || 'none'};
            font-family: Montserrat;
            font-size: 14px;
            color: #309F34;
            padding-bottom: 3mm;
            padding-left: 10mm;
            margin-top: 7mm;
            height: 12mm;
            width:25%;
         }

         @top-center {
            content: ${hCenter || 'none'};
            text-align: center;
            white-space: pre-line;
            font-family: Montserrat;
            font-size: 11px;
            color: #253764;
            margin-top: 7mm;
            height: 20mm;
            width:55%;
        }

        @top-right {
            content: ${hRight || 'none'};
            text-align: left;
            white-space: pre-line;
            font-family: Montserrat;
            font-size: 11px;
            color: #253764;
            margin-top: 7mm;
            height: 20mm;
            width:22%;
        } 

        @bottom-right {
          font-family: Montserrat;
          font-size: 11px;
          color: #253764​;
          padding-top: 3mm;
          padding-right: 4mm;
          margin-bottom: 12mm;
          content: counter(page) " of " counter(pages);
        }
        
        @bottom-left{
          font-family: Montserrat;
          font-size: 10px;
          color: #253764​;
          padding-top: 3mm;
          padding-left: 4mm;
          margin-bottom: 12mm;
          width:25%;
          content: 'Agrecalc Carbon Footprint Report';
        }
    }

    `
};