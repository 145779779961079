import React, { useCallback, useState } from "react";
import { PieChart, Pie, Sector, Cell, Legend, ResponsiveContainer, Tooltip, Label } from "recharts";
import { Paper, Typography } from "@mui/material";
import _ from "lodash";
import { addCommas } from "../../helpers/stringFormatHelper";
import { ChartTitleTypography } from "../CustomStyles/StyledComponents";
import {PieChartColours, agcColours}  from "../../helpers/colourHelper"

const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius,
            startAngle, endAngle, fill,
            payload, percent, title,value
    } = props;

    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
        <g>
            <text x={cx} y={cy} dy={20}
                textAnchor={"middle"}
                fontWeight="bold"
                fontSize="1.15em"
                fill={agcColours.PrussianBlue}>
                {`${addCommas(value.toFixed(2))} (${(percent * 100).toFixed(2)}%)`}
            </text>
            <text x={cx} y={cy + 15} dy={20}
                textAnchor={"middle"}
                fontWeight="bold"
                fontSize="1.15em"
                fill={agcColours.PrussianBlue}>
                {title}
            </text>
            <Sector
                cx={cx} cy={cy} 
                innerRadius={innerRadius} outerRadius={outerRadius}
                startAngle={startAngle} endAngle={endAngle}
                fill={fill}
            />
            <Sector cx={cx} cy={cy}
                startAngle={startAngle} endAngle={endAngle}
                innerRadius={outerRadius + 6} outerRadius={outerRadius + 10}
                fill={fill}
            />
        </g >
    );
};

const renderCustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <Paper sx={{ p: 1 }} variant='outlined'>
                <Typography variant="caption" fontWeight="bold">
                    {`${payload[0].name} : ${addCommas(payload[0].value?.toFixed(2))} kg CO₂-eq`}
                </Typography>
            </Paper>
        );
    }
    return null;
};

const renderColorfulLegendText = (value, entry, data) => {
    const { color } = entry;

    return <span style={{ color }}>{value?.concat(' (', data?.value?.toFixed(1), ' kg CO₂-eq)')}</span>;
};

export default function CircularPieChart({
    title, dataset, keyID,
    isAnimationActive = true,
    labelFontSize = "1.2em",
    legendWidth = "calc(100% - 10%)",
    displayTooltip = false,
    aspectRatio = 1.5
}) {

    const [activeIndex, setActiveIndex] = useState(-1);
    const onPieEnter = useCallback(
        (_, index) => { setActiveIndex(index);},
        [setActiveIndex]
    );

    const totalValue = _.sumBy(dataset, (obj) => { return obj.value; });

    return (
        <>
            <ChartTitleTypography gutterBottom align="center">
                {title}
            </ChartTitleTypography>
            <ResponsiveContainer aspect={aspectRatio}>
                <PieChart>
                    <Pie 
                        isAnimationActive={isAnimationActive}
                        key={keyID}
                        nameKey="title"
                        activeIndex={activeIndex}
                        activeShape={renderActiveShape}
                        data={dataset}
                        cx="calc(100% - 10%)"
                        cy="calc(100% - 10%)"
                        innerRadius= "65%"
                        outerRadius="90%" 
                        dataKey="value"
                        onMouseEnter={onPieEnter}
                    >
                        {dataset?.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={PieChartColours.getColour(index)} />
                        ))}
                        <Label value={`${addCommas(totalValue.toFixed(2))} kg CO₂-eq`}
                            position='center'
                            style={{ fontWeight: 700, fontSize: labelFontSize, fill: agcColours.AgrecalcBurntOrange}}
                             />
                    </Pie>
                    {displayTooltip && <Tooltip content={renderCustomTooltip} />}
                    <Legend
                        verticalAlign="bottom"
                        layout='horizontal'
                        align='center' wrapperStyle={{ width: legendWidth, whiteSpace: "break-spaces" }}
                        formatter={(value, entry, index) => renderColorfulLegendText(value, entry, dataset[index])}
                        position="inside"
                       
                    />
                </PieChart>
            </ResponsiveContainer>
        </>
    );
}
