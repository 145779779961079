import PropTypes from 'prop-types';
import { Box, FormControl, Grid, MenuItem } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FarmDetailsSelect, InfoTooltip } from '../CustomStyles/StyledComponents';
import { ResultStatus } from '../../helpers/reportResultsHelper';
import CreatePDFButton from '../Buttons/CreatePDFButton';

const ResultsReportsBar = ({
    handleReportSelect,
    reportList,
    reportId,
    calcStatus

}) => {

    return (
        <Grid container direction="row">
            <Grid item lg={4} md={4} sm={12} xs={12}>
                <FormControl variant="outlined" sx={{ width: '100%', float: 'left' }}>
                    <b style={{ fontSize: '1.15rem' }}>Report</b>
                    <span>
                        <FarmDetailsSelect  sx={{ width: '95%'}} displayEmpty value={reportId || ''}
                            onChange={handleReportSelect} autoFocus={true}>
                            <MenuItem value=""><em>Change Report...</em></MenuItem>
                            {reportList.map((option) => (
                                <MenuItem
                                    id={option.reportId} key={option.reportId} value={option.reportId}>{option.reportName}</MenuItem>
                            ))}
                        </FarmDetailsSelect>
                        <InfoTooltip placement="right"
                            title="Tip: If you would like to view a different report please select from this drop-down">
                            <InfoOutlinedIcon color='primary' sx={{ marginLeft: '2px', marginBottom: '5px', verticalAlign: 'bottom' }} fontSize='small' />
                        </InfoTooltip>
                    </span>
                </FormControl>
            </Grid>
            <Grid item lg={8} md={8} sm={12} xs={12} sx={{ textAlign: 'right' }} >
                {calcStatus === ResultStatus.Completed ? <CreatePDFButton referrer={'whole-farm'} /> : ''}
            </Grid>
        </Grid>

    )
}

ResultsReportsBar.propTypes = {
    handleReportSelect: PropTypes.func,
    reportList: PropTypes.arrayOf(PropTypes.object),
    reportId: PropTypes.string,
    calcStatus: PropTypes.number
};

export default ResultsReportsBar