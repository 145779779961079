import React from "react";
import { PieChart, Pie, Cell, Legend, ResponsiveContainer, Tooltip } from "recharts";
import { Paper, Typography } from "@mui/material";
import _ from "lodash";
import { ChartTitleTypography } from "../CustomStyles/StyledComponents";
import {PieChartColours}  from "../../helpers/colourHelper"

const RADIAN = Math.PI / 180;
const renderCustomLabel = ({ cx,cy, midAngle, innerRadius, outerRadius, percent, value, index}) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.75;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    // Values are in percentages for our customised chart.
    let displayPct = value || 0;
    let txtColour = PieChartColours.getLabelTextColour(PieChartColours.getColour(index))
    return ( displayPct < 2 ? null :
            < text x={x} y={y} fill={txtColour} textAnchor="middle" dominantBaseline="middle" >
                {`${displayPct.toFixed(1)}%`}
            </text >
    );
};

const renderCustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <Paper sx={{ p: 1 }} variant='outlined'>
                <Typography variant="caption" fontWeight="bold">
                    {`${payload[0].name} : ${payload[0].value?.toFixed(1)}%`}
                </Typography>
            </Paper>
        );
    }
    return null;
};

const renderColorfulLegendText = (value, entry, data) => {
    const { color } = entry;

    return <span style={{ color }}>{value?.concat(' (', data?.value?.toFixed(1), ' %)')}</span>;
};

/**
 *  Data needs to already be in percentage for this chart.
 */
export default function ColoredPieChart({
    title, dataset, keyID,
    cx="calc(100% - 10%)",
    cy="calc(100% - 10%)",
    outerRadius = "90%",
    isAnimationActive = true,
    legendWidth = "calc(100% - 10%)",
    aspectRatio = 1.5

}) {

    const validData = _.filter(dataset, d => d.value > 0);
    return (
        <>
            <ChartTitleTypography gutterBottom align="center">
                {title}
            </ChartTitleTypography>
            <ResponsiveContainer aspect={aspectRatio}>
                <PieChart>
                    <Pie
                        isAnimationActive={isAnimationActive}
                        key={keyID}
                        nameKey="title"
                        fontSize={10}
                        data={validData}
                        cx="calc(100% - 25%)"
                        cy="calc(100% - 25%)"
                        labelLine={false}
                        label={renderCustomLabel}
                        outerRadius={outerRadius}
                        fill="#8884d8"
                        dataKey="value"
                    >
                        {validData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={PieChartColours.getColour(index)} />
                        ))}
                    </Pie>
                    <Tooltip content={renderCustomTooltip} />
                    <Legend verticalAlign="bottom" layout='horizontal'
                        align='center' wrapperStyle={{ width: legendWidth, whiteSpace: "break-spaces" }}
                        formatter={(value, entry, index) => renderColorfulLegendText(value, entry, validData[index])}
                    />
                </PieChart>
            </ResponsiveContainer>
        </>
    );
}
