import { v4 as uuidv4 } from 'uuid';
import { isObjectEmpty } from './genericHelper.js';

export const createNewRecord = (sampleObjValues, newId = null) => {
    // creates a new object with emmpy data based on the keys of sampleObjValues
    if (!newId) {
        newId = uuidv4();
    }
    let map = Object.keys(sampleObjValues).reduce((accumulator, mappingProperty) => {
        return { ...accumulator, [mappingProperty]: null };
    }, {});
    return ({
        added: true,
        recordId: newId,
        value: map
    })
}


/* Checks if the element being deleted is the last with data
   * to avoid the row itself be deleted
   */
const isLastVisibleRow = (itemList) => {
    let countDeleted = 0
    for (const i of itemList) {
        countDeleted += i.deleted?1:0;
    }
    let visibleCount = itemList.length - countDeleted;
    return (visibleCount <= 1)
}

/* Deletes a "row" by removing the object from the array.
*  However we need to leave on entry empty (the last entry)
*/
export const deleteRow = (list, itemIdx, uuid) => {
    let rowObj = list[itemIdx];
    if (isLastVisibleRow(list)) {
        let newObject = createNewRecord(rowObj.value, uuid);
        list.push(newObject);
        rowObj.deleted = true;
    } else if (rowObj.added) {
        // just update the list since added objects haven't been
        // sent to server
        list.splice(itemIdx, 1);
    } else {
        rowObj.deleted = true;
    }
    // cleanup and leave only 1 empty element max
    let haveEmpty = false
    for (const r of list) {
        if (isObjectEmpty(r.value)) {
            if (haveEmpty) {
                r.deleted = true
            } else {
                haveEmpty = true
            }
        }
    }
}