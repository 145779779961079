import {
    Grid, Paper, Table, TableBody, TableContainer, TableHead
} from '@mui/material'
import React, { } from 'react'
import {
    ChartTitleTypography, FormDescriptionTypographyPDF,
    FormTitleTypographyPDF, ResultsTableCellPDF, ResultsTableRow
} from '../../../../components/CustomStyles/StyledComponents';
import { addCommas } from '../../../../helpers/stringFormatHelper';
import { ENTERPRISE_DETAILED_TABLES } from '../../../../constants/shellTables';

const Tables = ({ results = {}, firstChild = false }) => {

    const DataTable = ({ index, table, tableData, emissionUnit }) => {
        const addTextColor = (cellData) => {
            switch(cellData){
                case 'Low':  return <ResultsTableCellPDF color='#C94D49'>{cellData}</ResultsTableCellPDF>  
                case 'Medium':  return <ResultsTableCellPDF color='#EDA710'>{cellData}</ResultsTableCellPDF> 
                case 'High':  return <ResultsTableCellPDF color='#5ace5d'>{cellData}</ResultsTableCellPDF> 
                default : return <ResultsTableCellPDF>{cellData}</ResultsTableCellPDF>
            }
        }
        return <>
            {table &&
                <TableContainer sx={{ marginTop: '3mm',}}>
                    <ChartTitleTypography sx={{ mb: 2, mt:3 }}>{table.title}</ChartTitleTypography>
                    <Table id={index}>
                        <TableHead>
                            <ResultsTableRow>
                                {table.columns.map((column) => (
                                    <ResultsTableCellPDF>
                                        {column?.isDynamic ? emissionUnit : column.title}
                                    </ResultsTableCellPDF>
                                ))}
                            </ResultsTableRow>
                        </TableHead>
                        <TableBody>
                            {tableData && tableData.map((row) => (
                                <ResultsTableRow>
                                    {table.columns.map((column) => (
                                        addTextColor( addCommas(row[column.key]) || '--') 
                                    ))}
                                </ResultsTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </>
    }

    return (
        <Paper sx={{ maxWidth: '860px', pageBreakBefore: 'always', pageBreakAfter: 'auto', marginTop: '4mm', borderRadius: 0,}} p={2}>
            <Grid item xs={12}>
                <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column', marginTop: '3mm', borderRadius: 0,}}>
                    <FormTitleTypographyPDF fontSize={20} variant="h6" component="div" mb={1}>
                        {`Enterprise Emissions: ${results.enterpriseTitle}`}
                    </FormTitleTypographyPDF>
                    <FormDescriptionTypographyPDF variant="body2" fontSize={10} paragraph mb={1}>
                        {`The quick glance table below displays a breakdown of product-level emissions by source against the 
                        average benchmark value. The opportunity level (high, medium, or low) suggests the likelihood of cost effective improvement in for each emission source.`}
                    </FormDescriptionTypographyPDF>
                    <FormDescriptionTypographyPDF variant="body2" fontSize={10} paragraph mb={0}>
                        {`The physical performance table helps place emission results in context, by benchmarking enterprise 
                        KPIs against average values. This table should help evaluate the overall efficiency of the enterprise.`}
                    </FormDescriptionTypographyPDF>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Grid container direction='column'>
                    {ENTERPRISE_DETAILED_TABLES.map((table, index) => (
                        // results[table.key] && results[table.key].length > 0 &&
                        <Grid item xs={12} sx={{
                            pageBreakInside: 'avoid',
                            //pageBreakAfter: index === 0 ? 'always' : 'auto'
                        }}>
                            <DataTable
                                key={index}
                                index={index}
                                table={table}
                                tableData={results[table.key]}
                                emissionUnit={results?.emissionUnit}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Paper >
    )
}

export default Tables